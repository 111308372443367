<template>
  <div class="btm" :class="btmClass" v-show="TopMenuHeightLevel != 4">
    <div class="main-content">
      <GmapMap
        v-show="!showPtzPanel"
        ref="map"
        :center="center"
        :zoom="zoom"
        :minZoom="minZoom"
        :options="getMapOptions()"
        style="width: 100%; height: 100%"
      >
        <GmapMarkerWithLabel
          v-for="(m, index) in showingMarkers"
          :key="index"
          :position="m.position"
          :icon="getIcon(m.id, m.deviceType)"
          :zIndex="getZIndex(m.id, index)"
          :label-content="getContent(m)"
          :label-anchor="getAnchor(m.id)"
          :label-in-background="true"
          label-class="marker-label"
          :label-visible="true"
          :clickable="true"
          :draggable="false"
          @click="onMarkerClick(m)"
        />

        <gmap-polyline v-if="showVideoMarkerPath" :path.sync="videoPath" :options="getPathOption()" />
        <gmap-polyline v-else :path.sync="trackUserPath" :options="getPathOption()" />
        
      </GmapMap>
      <!-- PZT Panel -->
      <PtzPanel v-if="showPtzPanel" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import GmapMarkerWithLabel from './Btm/MarkerLabel.vue'
import PtzPanel from './Btm/PtzPanel.vue'
import { LoginMapCenter, MaxZoomIn } from '@/config/map.js'
import { getDeviceModelIcon } from '@/config/account.js'
import { formatTime } from '@/utils/lib.js'

export default {
  name: 'Btm',
  components: {
    GmapMarkerWithLabel,
    PtzPanel,
  },
  data() {
    return {
      center: LoginMapCenter.center,
      zoom: LoginMapCenter.zoom,
      minZoom: 1,
      mapStyle: { width: '100%', height: '100%' }
    }
  },
  computed: {
    ...mapState([
      'liveMode',
      'TopMenuHeightLevel',
      'LeftMenuOpen',
      'RightMenuOpen',
      'showEventCardDetail',
      'connectionList',
      'liveList',
      'eventList',
      'selectedUsers',
      'trackUser',
      'eventCardDetailObj',
      'videoMarker',
      'videoPath',
      'userList',
      'singleUrlUserID'
    ]),
    ...mapState('ptz', ['showPtzPanel']),
    ...mapGetters(['gpsMarkers', 'trackUserPath']),
    showVideoMarkerPath() {
      // 打開 video 視窗 && 播放歷史影片模式 --> 顯示播放影片設備的icon & 路徑
      return this.TopMenuHeightLevel > 1 && !this.liveMode
    },
    showingVideoMarker() {
      if (!this.showVideoMarkerPath) return null
      if (this.videoMarker.position && this.videoMarker.position.lat) {
        return this.videoMarker
      } else {
        const finalMarker = this.gpsMarkers.find((marker) => marker.id === this.singleUrlUserID)
        return finalMarker ? finalMarker : null
      }
    },
    showingVideoMarkerId() {
      return this.showingVideoMarker ? this.showingVideoMarker.id : ''
    },
    showingMarkers() {
      if (this.showVideoMarkerPath) {
        return this.showingVideoMarker ? [this.showingVideoMarker] : []
      } else {
        return this.gpsMarkers
      }
    },
    showTimestamp() {
      return this.showVideoMarkerPath && Object.keys(this.videoMarker).length === 0
    },
    eventModalClass() {
      if (this.showEventCardDetail) {
        if (this.eventCardDetailObj.chasing == 1) {
          return 'chasing-wrap'
        }
        return 'urgent-and-comparing-wrap'
      }
      return 'empty-wrap'
    },
    btmClass() {
      if (!this.LeftMenuOpen && !this.RightMenuOpen) {
        if (this.TopMenuHeightLevel == 1) {
          return 'btm-one'
        }
        if (this.TopMenuHeightLevel == 2) {
          return 'btm-two'
        }
        if (this.TopMenuHeightLevel == 3) {
          return this.showPtzPanel ? 'btm-three-ptz' : 'btm-three'
        }
        return 'btm-four'
      }
      if (this.LeftMenuOpen && this.RightMenuOpen) {
        if (this.TopMenuHeightLevel == 1) {
          return 'btm-lr-one'
        }
        if (this.TopMenuHeightLevel == 2) {
          return 'btm-lr-two'
        }
        if (this.TopMenuHeightLevel == 3) {
          return this.showPtzPanel ? 'btm-lr-three-ptz' : 'btm-lr-three'
        }
        return 'btm-lr-four'
      }
      if (this.LeftMenuOpen) {
        if (this.TopMenuHeightLevel == 1) {
          return 'btm-l-one'
        }
        if (this.TopMenuHeightLevel == 2) {
          return 'btm-l-two'
        }
        if (this.TopMenuHeightLevel == 3) {
          return this.showPtzPanel ? 'btm-l-three-ptz' : 'btm-l-three'
        }
        return 'btm-l-four'
      }
      if (this.RightMenuOpen) {
        if (this.TopMenuHeightLevel == 1) {
          return 'btm-r-one'
        }
        if (this.TopMenuHeightLevel == 2) {
          return 'btm-r-two'
        }
        if (this.TopMenuHeightLevel == 3) {
          return this.showPtzPanel ? 'btm-r-three-ptz' : 'btm-r-three'
        }
        return 'btm-r-four'
      }
      return 'none'
    },
  },
  watch: {
    'selectedUsers.length'() {
      if (this.selectedUsers.length == 1)
        // 勾選第一位時調整地圖位置，再勾選則不調整
        this.zoomExtends()
    },
    'trackUser.id'() {
      this.panToTrackUser()
      this.updateShowPtzPanel(false) // 切換trackUser時，關閉PtzPanel
    },
    showingVideoMarkerId() {
      if (this.showingVideoMarkerId)
        this.$refs.map.panTo(this.showingVideoMarker.position)
    },
    'singleUrlUserID'() {
      setTimeout(() => {
        if (this.singleUrlUserID && this.showingVideoMarker) {
          this.$refs.map.panTo(this.showingVideoMarker.position)
        }
      }, 300)
    },
    'videoPath.length'() {
      // 移動地圖到最新的位置點
      if (this.videoPath.length > 0)
        this.$refs.map.panTo(this.videoPath[this.videoPath.length - 1])
    },
    gpsMarkers(nVal, oVal) {
      // 處理有資料時，地圖會自動放大 => 會有預設地圖閃過的狀況
      if (oVal.length === 0 && nVal.length > 0) {
        this.zoomExtends()
      }
    }
  },
  mounted() {
    this.$refs.map.$mapPromise.then(() => {
      this.zoomExtends()

      // 重整時倍率會抓到一開始設定的15而無法修正，所以加上timeout
      this.$nextTick(() => {
        // 當marker距離很接近時，放大倍率大增，需要再調整成zoom=15
        if (this.$refs.map.$mapObject.getZoom() > MaxZoomIn) {
          this.$refs.map.$mapObject.setZoom(MaxZoomIn)
        }
      })
    })
    this.$bus.$on('panToTrackUser', this.panToTrackUser)
  },
  beforeDestroy() {
    this.$bus.$off('panToTrackUser', this.panToTrackUser)
  },
  methods: {
    ...mapMutations('ptz', ['updateShowPtzPanel']),
    getMapOptions() {
      return {
        mapTypeId: 'terrain',
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUI: false,
        styles: [
          // {
          //   featureType: 'administrative.land_parcel',
          //   elementType: 'labels',
          //   stylers: [
          //     {
          //       visibility: 'off'
          //     }
          //   ]
          // },
          // {
          //   featureType: 'poi',
          //   elementType: 'labels.text',
          //   stylers: [
          //     {
          //       visibility: 'off'
          //     }
          //   ]
          // },
          {
            featureType: 'poi.business',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          }
          // {
          //   featureType: 'road',
          //   elementType: 'labels.icon',
          //   stylers: [
          //     {
          //       visibility: 'off'
          //     }
          //   ]
          // },
          // {
          //   featureType: 'road.local',
          //   elementType: 'labels',
          //   stylers: [
          //     {
          //       visibility: 'off'
          //     }
          //   ]
          // },
          // {
          //   featureType: 'transit',
          //   stylers: [
          //     {
          //       visibility: 'off'
          //     }
          //   ]
          // }
        ]
      }
    },
    getIcon(userId, dType) {
      const user = this.userList.find((user) => user.id === userId)
      const userDeviceType = user ? user.deviceModelId : null

      // device type / focus or not / status
      let deviceType = userDeviceType
        ? Number(getDeviceModelIcon(userDeviceType))
        : this.getDeviceType(dType)
      let mapDeviceType =
        typeof deviceType === 'number' ? `map_device_model_${deviceType}` : deviceType
      let isFocus = this.isFocus(userId) ? '_focus_' : '_'
      let status = this.getDeviceStatus(userId)
      let icon = mapDeviceType + isFocus + status + '.svg'
      let url = require(`@/assets/icons/${icon}`)

      return {
        url: url,
        scaledSize: this.isFocus(userId)
          ? { height: 68, width: 76, f: 'px', b: 'px' }
          : { height: 48, width: 56, f: 'px', b: 'px' }
      }
    },
    getDeviceType(dType) {
      let deviceType = 'common' // 一般
      let type
      if (dType) type = typeof dType === 'number' ? dType : dType.toLowerCase()

      if (type) {
        if (typeof type === 'number') {
          // 套用新 設備類型
          deviceType = dType
        } else {
          // 穿戴式裝置（BWC or Salute）
          if (type.includes('bwc') || type.includes('salute')) deviceType = 'bwc'
          // 巡邏APP
          else if (type.includes('patrol')) deviceType = 'patrol-ext'
          // Phone手機
          else if (type.includes('ios') || type.includes('android')) deviceType = 'patrol-int'
          // 箱機 => 判斷”PTZ”/“r3” + 箱機(bm/bl/bs開頭)
          // 球機 => 判斷”PTZ”/“r3” + 球機(s開頭)
          else if (type.includes('ptz') || type.includes('r3')) {
            if (type.charAt(0) === 's') deviceType = 'dome-camera' // 球機
            const startChar = type.slice(0, 2)
            if (startChar === 'bm' || startChar === 'bl' || startChar === 'bs')
              deviceType = 'box-camera' // 箱機
          }
        }
      }

      return deviceType
    },
    isFocus(userId) {
      let bFocus = false
      if (Object.keys(this.trackUser).length > 0) {
        if (userId === this.trackUser.id) bFocus = true
      }
      return bFocus
    },
    getDeviceStatus(userId) {
      if (this.getSosStatus(userId) || this.getChasingStatus(userId)) return 'sos'

      // 若userId在connectionList中表示Online
      // 2022.08.29 新增若userId在liveList中，若live.gps.old = 1，顯示舊GPS，lock=1 顯示鎖定GPS
      // 否則顯示上線狀態;若都不在connectionList, liveList則顯示off-line
      const connIdx = this.connectionList.findIndex(
        (conn) => conn.userAccount === userId && conn.gps
      )
      const liveIdx = this.liveList.findIndex((live) => live.id === userId && live.gps)
      if (connIdx < 0 && liveIdx < 0) return 'off-line'
      else {
        if (liveIdx >= 0) {
          let live = this.liveList[liveIdx]
          if (live.gps.lock === 1) return 'lock-gps'
          else if (live.gps.old === 1) return 'old-gps'
        } else if (connIdx >= 0) {
          let conn = this.connectionList[connIdx]
          if (conn.gps.lock === 1) return 'lock-gps'
          else if (conn.gps.old === 1) return 'old-gps'
        }
        return 'new-gps'
      }
    },
    getZIndex(userId, index) {
      if (Object.keys(this.trackUser).length > 0) {
        if (userId == this.trackUser.id) {
          return this.gpsMarkers.length
        }
        let p = this.gpsMarkers.findIndex(mark => mark.id == this.trackUser.id)
        return (index > p) ? index : index + 1
      }
      return index + 1
    },
    getContent(marker) {
      let content = `
        <div style="font-size:18px; line-height:20px">
          ${this.getVideoTitle(marker.id)}
        </div>
        <span style="font-size:16px; line-height:18px">
          (${marker.id})
        <span>
      `
      if (this.showTimestamp) {
        content += `
          <div style="font-size:12px; line-height:12px">
            ${formatTime(marker.position.timestamp)}
          </div>
        `
      }

      return content
    },
    // 因為video title 可能被更動，所以要透過userId重新取得video title
    getVideoTitle(userId) {
      let user = this.userList.find(user => user.id === userId)
      return user ? user.video.title : ''
    },
    
    getAnchor(id) {
      return this.isFocus(id) ? { x: 0, y: 120 } : { x: 0, y: 100 }
    },
    getPathOption() {
      // 若trackUser.id在connectionList中表示Online，返回true，反之是離線狀態，返回false
      return {
        strokeColor: this.getConnectionStatus(this.trackUser.id)
          ? '#FE6847'
          : '#aaaaaa'
      }
    },
    getSosStatus(userId) {
      // connections API 中 SOS 欄位 只要 > 0, 就表示有 SOS 事件 (>0 的數字是 SOS 的事件 id)
      let idx = this.connectionList.findIndex(
        (conn) => conn.sos > 0 && conn.userAccount == userId // 注意user資料的id對應connection的account
      )
      return idx >= 0 ? true : false
    },
    getChasingStatus(userId) {
      // 若userId在connectionList中且lprChasing>0
      let idx = this.connectionList.findIndex(conn => conn.userAccount == userId && conn.lprChasing > 0)
      
      // 若userId在eventList中表示正在追車，返回true，反之返回false
      // let idx = this.eventList.findIndex(
      //   (event) => event.user.id == userId && event.chasing == 1
      // )
      return idx >= 0 ? true : false
    },
    getConnectionStatus(userId) {
      // 若userId在connectionList中表示Online，返回true，反之是離線狀態，返回false
      // 2022.08.29 新增若userId在liveList中，若live.gps.old = 1，顯示離線，否則顯示上線狀態
      let connIdx = this.connectionList.findIndex(item => item.userAccount === userId && item.gps)
      if (connIdx >= 0) return true
      else {
        let live = this.liveList.find(item => item.id === userId && item.gps)
        if (live) {
          return live.gps.old === 1 ? false : true
        } else return false
      }
    },
    zoomExtends() {
      // 先判斷是否每個marker都在邊界之內
      // let nOutside = 0
      // this.markers.forEach((marker) => {
      //   if (!this.$refs.map.$mapObject.getBounds().contains(marker.position)) {
      //     nOutside += 1
      //   }
      // })
      // if (nOutside == 0) {
      //   return
      // }

      if (this.gpsMarkers.length > 1) {
        let bounds = new window.google.maps.LatLngBounds()
        this.gpsMarkers.forEach((marker) => {
          bounds.extend(marker.position)
        })
        this.$refs.map.fitBounds(bounds)

        // 當marker距離很接近時，放大倍率大增，需要再調整成zoom=15
        if (this.$refs.map.$mapObject.getZoom() > MaxZoomIn) {
          this.$refs.map.$mapObject.setZoom(MaxZoomIn)
        }
        bounds = null
      } else {
        if (this.gpsMarkers.length == 1) {
          this.$refs.map.$mapObject.setZoom(MaxZoomIn)
          this.$refs.map.panTo(this.gpsMarkers[0].position)
        }
      }
    },
    panToTrackUser() {
      if (this.trackUserPath.length > 0)
        this.$refs.map.panTo(this.trackUserPath[this.trackUserPath.length - 1])
      else {
        let idx = this.gpsMarkers.findIndex((marker) => marker.id === this.trackUser.id)
        if (idx >= 0) {
          this.$refs.map.panTo(this.gpsMarkers[idx].position)
        }
      }
    },
    onMarkerClick(marker) {
      let idx = this.userList.findIndex(user => user.id === marker.id)
      if (idx === -1) return
      let user = {
        id: marker.id,
        name: marker.name,
        groupId: this.userList[idx].groupId,
        label: marker.name + ' (' + marker.id + ')'
      }  
      this.$store.commit('setTrackUser', user)
    }
  }
}
</script>

<style>
.marker-label {
  color: #191919;
  font-size: 18px;
  background: #f6feaa;
  padding: 4px 12px;
  border-radius: 5px;
  border: #afbe8f 1px solid;
  text-align: center;
  transform: translateX(-50%);
  opacity: 0.7;
}
</style>

<style scoped>
.btm-one {
  grid-area: second / left-close / end / end;
}

.btm-two {
  grid-area: onethird / left-close / end / end;
}

.btm-three {
  grid-area: twothird / left-close / end / end;
}

.btm-three-ptz {
  grid-area: ptz / left-close / end / end;
}

.btm-l-one {
  grid-area: second / left-open / end / end;
}

.btm-r-one {
  grid-area: second / left-close / end / right-open;
}

.btm-lr-one {
  grid-area: second / left-open / end / right-open;
}

.btm-l-two {
  grid-area: onethird / left-open / end / end;
}

.btm-r-two {
  grid-area: onethird / left-close / end / right-open;
}

.btm-lr-two {
  grid-area: onethird / left-open / end / right-open;
}

.btm-l-three {
  grid-area: twothird / left-open / end / end;
}

.btm-l-three-ptz {
  grid-area: ptz / left-open / end / end;
}

.btm-r-three {
  grid-area: twothird / left-close / end / right-open;
}

.btm-r-three-ptz {
  grid-area: ptz / left-close / end / right-open;
}

.btm-lr-three {
  grid-area: twothird / left-open / end / right-open;
}

.btm-lr-three-ptz {
  grid-area: ptz / left-open / end / right-open;
}

.btm {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-rows: minmax(0, 1fr);
}

.urgent-and-comparing-wrap {
  /*max-width: 100%;*/
  /*border: 2px solid #ff4b00;*/
  grid-area: 2 / 2 / 20 / 20;
  z-index: 1;
}

.chasing-wrap {
  grid-area: 13 / 13 / 21 / 21;
  z-index: 1;
}

.user-info-grid-wrap {
  /*max-width: 100%;*/
  /*border: 2px solid #ff4b00;*/
  grid-area: 6 / 2 / 20 / 10;
  z-index: 1;
}

.empty-wrap {
  grid-area: 1 / 1 / 1 / 1;
}

.main-content {
  grid-area: 1 / 1 / 21 / 21;
  box-sizing: border-box;
}
</style>