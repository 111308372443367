<template>
  <div class="video-download-mask">
    <div class="video-download-modal">
      <div class="close" @click.stop="$emit('close')">
        <img src="@/assets/icons/clear.svg" alt="close" />
      </div>
      <div v-if="queryByTime" class="search-video">
        <DateRangeSelect
          class="dt-select" 
          v-model="dateRange"
          :range="true"
          :title="$t('search_time_interval')"
        />

        <MagnifierSearchBtn
          class="btn-search"
          :active="searchLoading"
          @click="searchVideo"
        />
      </div>

      <div class="header">
        <div class="select-all" @click="isCheckAll = !isCheckAll">
          <img v-if="isCheckAll" src="@/assets/icons/checkbox-yellow-checked.svg" alt="">
          <img v-else-if="isIntermediate" src="@/assets/icons/checkbox-yellow-minus.svg" alt="">
          <img v-else src="@/assets/icons/checkbox-white-square.svg" alt="">
          
          <span>{{ $t('video_select_all') }}</span>
          <div class="bar"></div>
          <span>{{ $t('video_selected')/*已勾選*/ }}：{{ checkCount }}</span>
        </div>
        <div class="actions">
          <TooltipElement :content="$t('video_unselect')" alwaysShow placement="top">
            <div v-if="permissionV2.videoManagement > 0" class="icon">
              <img class="bat-icon" :class="{ disabled: !checkCount }" src="@/assets/icons/pin-not.svg" @click="keepAllCheckedVideo(0)" />
            </div>
          </TooltipElement>
          <TooltipElement :content="$t('video_keep_select')" alwaysShow placement="top">  
            <div v-if="permissionV2.videoManagement > 0" class="icon">  
              <img class="bat-icon" :class="{ disabled: !checkCount }" src="@/assets/icons/pin.svg" @click="keepAllCheckedVideo(1)" />
            </div>
          </TooltipElement>
          <TooltipElement v-if="canDelete" :content="$t('video_delete_select')" alwaysShow placement="top">  
            <div v-if="permissionV2.videoManagement > 0" class="icon">  
              <img class="bat-icon" :class="{ disabled: !checkCount }" src="@/assets/icons/TrashCan.svg" @click="batchDelect" />
            </div>
          </TooltipElement>
          <TooltipElement :content="$t('video_download_select')" alwaysShow placement="top">  
            <div class="icon">  
              <img class="bat-icon" :class="{ disabled: !checkCount }" src="@/assets/icons/download.svg" @click="batchDownload" />
            </div>
          </TooltipElement>
        </div>  
      </div>

      <div class="content" :class="{ 'query-by-time': queryByTime }">
        <div v-for="(item, index) in showVideoList" :key="item.id" class="card">
          <div class="video-check" @click="handleVideoCheck(item)">
            <!-- <div v-if="bDevMode">{{ `#${item.id}` }}</div> -->
            <img v-if="item.isCheck" src="@/assets/icons/checkbox-yellow-checked.svg" alt="">
            <img v-else src="@/assets/icons/checkbox-white-square.svg" alt="">
          </div>
          <div class="video-img" @click="backPlay(index, item.startTime)">
            <img v-lazy="item.snapshotUrl" alt="">
            <img src="@/assets/icons/icon-play.svg" class="icon-play" alt="">
          </div>
          <div class="video-info">
            <div class="info">
              <img src="@/assets/icons/video.svg">
              <span>{{ item.title }}</span>
            </div>
            <div class="info">
              <img src="@/assets/icons/Camera.svg">
              <span>{{ item.user.id }}</span>
            </div>
            <div class="info">
              <img src="@/assets/icons/time-solid.svg">
              <span>{{ formatTime(item.startTime) }}</span>
            </div>
            <div class="info">
              <img src="@/assets/icons/device-type.svg">
              <span>{{ item.device.info.type }}</span>
            </div>
            <div class="info">
              <img src="@/assets/icons/FileType.svg">
              <span>{{ item.type }}</span>
            </div>
            <div class="download">
              <span v-if="item.type === 'MP4'">
                <el-tooltip class="item" effect="dark" :content="$t('video_download')" placement="top">
                  <img class="fDownLoad" src="@/assets/icons/download.svg" @click="downloadMp4(item.downloadUrl)" />
                </el-tooltip>
              </span>
              <span v-else>
                <img class="hlsDownLoad" src="@/assets/icons/download.svg" />
              </span>
            </div>
          </div>
          <div class="video-keep">
            <div class="item">
              <div>Duration</div>
              <div>{{ formatSeconds(item) }}</div>
            </div>
            <div class="item">
              <div>Size</div>
              <div>{{ formatBytes(item.size) }}</div>
            </div>
            <div  v-if="permissionV2.videoManagement > 0" @click="handleVideoKeep(item)" class="item">
              <img v-if="item.keep" src="@/assets/icons/pin.svg" />
              <img v-else src="@/assets/icons/pin-empty.svg" alt="">
              <span>{{ $t('video_keep')/*保留*/ }}</span>
            </div>
            <div v-else class="item"></div>
          </div>
        </div>

        <Loading :active.sync="searchLoading"
          color="#FFF"
          background="transparent"
          :width="80"
          :height="80"
          :opacity="0.1"
          :can-cancel="true"
          :is-full-page="true">
        </Loading>
      </div>
      <div class="footer">
        <Pagination
          class="pagination"
          :currentPage="videoCurrentPage"
          :total="showVideoTotal"
          :pageSize="videoPageSize" 
          mode="multiple"
          @getPageData="getPageVideos"
        ></Pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Base/Pagination.vue'
import { apiVideoUpdate, apiVideoDelete } from '@/api/index.js'
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import multiDownload from 'multi-download'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { getDashboardVideoDateRangeSetting } from '@/config/dateRange.js'
import { formatTime /*, isDevMode*/ } from '@/utils/lib.js'
import MagnifierSearchBtn from '@/components/Base/MagnifierSearchBtn.vue'

export default {
  name: 'VideoDownload',
  components: {
    Loading,
    Pagination,
    TooltipElement: () => import('@/components/Base/TooltipElement.vue'),
    DateRangeSelect: () => import('@/components/Base/DateRangeSelect.vue'),
    MagnifierSearchBtn,
  },
  props: {
    queryByTime: {
      type: Boolean,
      default: true
    },
    videoPageSize: {
      type: Number,
      default: 20
    },
    canDelete: {
      type: Boolean,
      default: true
    },
    postIds: {
      type: Array, // Array[String]
      default() {
        return []
      }
    },
    // 辨別是 history or dashboard 在使用
    caller: {
      type: String,
      default: 'history'
    }
  },
  data() {
    return {
      dateRange: [
        /*sT, eT*/
      ],
      videoCurrentPage: 1
    }
  },
  computed: {
    ...mapState('history', ['historyEventModalType']),
    ...mapState('video', [
      'eventInfo',
      'searchLoading',
      'queryVideoTotal',
      'queryVideoList',
      'queryVideoParams',
      'eventVideoList',
      'eventVideoTotal',
    ]),
    ...mapGetters('history', ['historyModalEvent']),
    ...mapGetters('historyUrgent', ['urgMode', 'urgModalEvent']),
    ...mapGetters('historyVideo', ['modalVideo']),
    // bDevMode() {
    //   return isDevMode()
    // },
    permissionV2() {
      return this.$store.state.permissionV2
    },
    modalEvent() {
      const eventMap = {
        lpr: this.historyModalEvent,
        urgent: this.urgModalEvent,
        video: this.modalVideo
      }
      return this.caller === 'history'
        ? eventMap[this.historyEventModalType]
        : null
    },
    modalEventPostId() {
      if (this.caller === 'dashboard') return null

      return this.historyEventModalType === 'urgent' && this.urgMode === 'sos'
        ? this.modalEvent.userAccount
        : this.modalEvent.user.id
    },
    showVideoList() {
      return this.queryByTime ? this.queryVideoList : this.eventVideoList
    },
    showVideoTotal() {
      return this.queryByTime ? this.queryVideoTotal : this.eventVideoTotal
    },
    checkCount() {
      return this.showVideoList.filter(item => item.isCheck).length
    },
    isCheckAll: {
      get() {
        return this.showVideoList?.length > 0 && this.checkCount === this.showVideoList.length
      },
      set(val) {
        this.showVideoList.forEach(item => {
          if (item.isCheck === undefined) this.$set(item, 'isCheck', val)
          else item.isCheck = val
        })
      }
    },
    isIntermediate() {
      return this.checkCount > 0 && this.checkCount < this.showVideoList.length
    },
  },
  methods: {
    ...mapMutations('video', [
      'updateIsShowEventVideoPanel',
      'updateIsShowVideoDownloadPanel',
      'updateIsBackPlay',
      'updatePlaySpecificTime',
      'updateCaller',
      'updateQueryVideoIndex',
      'updateEventVideoIndex',
    ]),
    ...mapActions('video', ['getQueryVideoList']),
    formatTime,
    downloadMp4(downloadUrl) { // 下載影片
      window.open(downloadUrl)
    },
    handleVideoKeep(item) { // 設定保留 或 取消保留
      const keep = item.keep ? 0 : 1
      this.updateVideoKeep([item.id], keep)
    },
    async updateVideoKeep(arrId, keep) { // 設定保留 或 取消保留
      const params = {
        id: arrId, // [影片id1, id2, ...]
        keep: keep // 1:保留, 0:取消保留
      }
      const res = await apiVideoUpdate(params)
      if (res.status === 200) {
        // 更新資料
        arrId.forEach(id => {
          const index = this.showVideoList.findIndex(item => item.id === id)
          if (index > -1) this.showVideoList[index].keep = keep
        })
        if (keep) {
          // this.$message.success(this.$t('video_set_keep')) // 影片已設定保留！
          this.$notify({
            type: 'success',
            message: this.$t('video_set_keep') // 影片已設定保留！
          })
        }
        else {
          // this.$message(this.$t('video_no_keep')) // 影片已取消保留！
          this.$notify({
            // type: 'success',
            message: this.$t('video_no_keep') // 影片已取消保留！
          })
        }
      }
    },
    keepAllCheckedVideo(keep) { // 全部保留 或 全部取消
      // 1:保留, 0:取消保留
      if (this.checkCount === 0) {
        // return this.$message.warning(this.$t('video_none_select')) // 無勾選資料！
        return this.$notify({
          type: 'warning',
          message: this.$t('video_none_select') // 無勾選資料！
        })
      }

      const ids = this.showVideoList.filter(item => item.isCheck).map(item => item.id)
      this.updateVideoKeep(ids, keep)
    },
    async batchDelect() {
      if (this.checkCount === 0) {
        // return this.$message.warning(this.$t('video_none_select')) // 無勾選資料！
        return this.$notify({
          type: 'warning',
          message: this.$t('video_none_select') // 無勾選資料！
        })
      }

      this.$confirm(this.$t('video_delete_checked'), this.$t('alarm_hint'), {
        confirmButtonText: this.$t('ok'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(async() => {
        // this.$message.success(this.$t('video_delete')) // 影片已刪除！
        this.$notify({
          type: 'success',
          message: this.$t('video_delete') // 影片已刪除！
        })
        const ids = this.showVideoList
          .filter(item => item.isCheck && item.keep === 0)
          .map(item => item.id)

        const res = await apiVideoDelete({ id: ids })
        if (res.status == 200) {
          // this.$message.success(this.$t('video_delete')) // 影片已刪除！
          this.$notify({
            type: 'success',
            message: this.$t('video_delete') // 影片已刪除！
          })
          // 重新搜尋影片
          const params = { ...this.queryVideoParams }
          params.index = (this.videoCurrentPage - 1) * this.videoPageSize
          this.getQueryVideoList(params)
        }
      })
    },
    batchDownload() {
      if (this.checkCount === 0)
        // return this.$message.warning(this.$t('video_none_select')) // 無勾選資料！
        return this.$notify({
          type: 'warning',
          message: this.$t('video_none_select') // 無勾選資料！
        })

      const urls = this.showVideoList.filter(item => item.isCheck).map(item => item.downloadUrl)
      multiDownload(urls)
    },
    getPageVideos(page) {
      this.videoCurrentPage = page
      const params = { ...this.queryVideoParams }
      params.index = (page - 1) * this.videoPageSize

      this.getQueryVideoList(params)
    },
    async searchVideo() {
      const params = {
        index: 0,
        count: this.videoPageSize, 
      }

      if (this.postIds && this.postIds.length > 0) 
        params.postId = this.postIds
      else 
        params.postId = this.eventInfo.postId

      if (this.dateRange?.length > 0) {
        let [st, et] = this.dateRange
        params.startTime = new Date(st).toISOString()
        params.stopTime = new Date(et).toISOString()
      }

      // if (this.queryByTime) {
      //   params.index = 0
      //   params.count = 100 // 加快搜尋速度（count 最大值為 100）
      //   if (this.postIds && this.postIds.length > 0) {
      //     params.postId = this.postIds
      //   } else {
      //     if (this.caller === 'history') {
      //       params.postId = [this.modalEventPostId]
      //     }
      //   }
      // }

      this.videoCurrentPage = 1
      await this.getQueryVideoList(params) // Get
      // ---------------------------------------------------------------------

      if (this.queryByTime) {
        if (!this.searchLoading && this.queryVideoList.length <= 0) {
          this.$notify({
            type: 'warning',
            title: this.$t('search_hint') /* 提示 */,
            message: this.$t('search_hint_nodata')
          })
        }
      }
    },
    handleVideoCheck(item) {
      if (item.isCheck === undefined) this.$set(item, 'isCheck', true)
      else item.isCheck = !item.isCheck
    },
    // 秒數格式化
    formatSeconds(item) {
      let totalSeconds = 0 // item.length
      if (item.stopTime) {
        const diff = new Date(item.stopTime).getTime() - new Date(item.startTime).getTime()

        totalSeconds = Math.floor(diff / 1000)
      }

      let hours = Math.floor(totalSeconds / 3600)
      totalSeconds %= 3600
      let minutes = Math.floor(totalSeconds / 60)
      let seconds = totalSeconds % 60
      minutes = String(minutes).padStart(2, "0")
      hours = String(hours).padStart(2, "0")
      seconds = String(seconds).padStart(2, "0")
      return hours + ":" + minutes + ":" + seconds
    },
    // 轉換影音檔案大小數值
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes'
      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      const i = Math.floor(Math.log(bytes) / Math.log(k))
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
    },
    backPlay(index, playTime) {
      this.updateIsShowEventVideoPanel(false)
      this.updateIsShowVideoDownloadPanel(false)
      this.updateIsBackPlay(true)
      this.updateBackPlayVideoIndex(index)
      // playTime 為utc時間, updatePlaySpecificTime 接收的是當下時區的時間, 所以要將utc時間轉換成當下時區的時間
      const playVideoTime = new Date(this.formatTime(playTime))
      this.updatePlaySpecificTime(playVideoTime)
    },
    updateBackPlayVideoIndex(index) {
      if (this.queryByTime) {
        this.updateQueryVideoIndex(index)
      } else {
        this.updateEventVideoIndex(index)
      }
    },
    getHistoryEventTimeInterval() {
      switch (this.historyEventModalType) {
        case 'lpr':
          return [
            this.historyModalEvent.chasingStartTime,
            this.historyModalEvent.chasingEndTime
          ]
        case 'urgent':
          if (this.urgMode === 'sos') {
            return [this.urgModalEvent.startTime, this.urgModalEvent.endTime]
          } else {
            return [
              this.urgModalEvent.chasingStartTime,
              this.urgModalEvent.chasingEndTime
            ]
          }
        case 'video':
          return [this.modalVideo.startTime, this.modalVideo.stopTime]
        default:
          return []
      }
    }
  },
  mounted() {
    if (this.queryByTime) {
      this.updateCaller(this.caller)
      if (this.caller === 'dashboard') {
        const { defaultDateRange } = getDashboardVideoDateRangeSetting()
        this.dateRange = [defaultDateRange[0], defaultDateRange[1]] // 現在 到 三天前
        this.searchVideo()
      } else if (this.caller === 'history') {
        this.videoCurrentPage = this.queryVideoParams.index / this.videoPageSize + 1
        this.dateRange = [this.queryVideoParams.startTime, this.queryVideoParams.stopTime]
      }
    }
  },
  beforeDestroy() {
    this.updateCaller('history')
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.video-download-mask {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);

  .video-download-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 84%;
    height: 86%;
    padding-top: px2rem(40);
    background-color: #282942;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

    .search-video {
      // width: 30%;
      display: flex;
      align-items: center;
      padding: 0 px2rem(70) px2rem(12);
      .dt-select {
        width: px2rem(400);
        margin-right: 10px;
      }

      .btn-search {
        align-self: flex-end;
        // width: 110px;
        // height: 48px;
        // border: 1px solid #FFC600;
        // border-radius: 9px;
        // background-image: url('../../assets/icons/feather-search.svg');
        // background-repeat: no-repeat;
        // background-position: center center;
        // display: flex;
        // justify-content: center;  
        // align-items: center;
        // margin-top: 32px;
        // cursor: pointer;

        // &:hover {
        //   background: #FFC600;
        //   background-image: url('../../assets/icons/feather-search-w.svg');
        //   background-repeat: no-repeat;
        //   background-position: center center;
        // }
      }

      // .loading {
      //   background: #FFC600;
      // }

      // .btn-search.loading:hover {
      //   background-image: url('../../assets/icons/feather-search.svg');
      // }

      // .ring {
      //   width: 30px;
      //   height: 30px;
      //   position: relative;
      //   color: inherit;
      //   display: inline-block;
      //   box-sizing: content-box;
      //   animation: ld-cycle 1s infinite linear;
      // }

      // .ring:after {
      //   content: " ";
      //   display: block;
      //   width: 60px;
      //   height: 60px;
      //   box-sizing: border-box;
      //   transform-origin: 0em 0em;
      //   transform: translateZ(0) scale(0.5);
      //   backface-visibility: hidden;
      //   border-radius: 50%;
      //   border: 7px solid #555555;
      //   border-left-color: transparent;
      // }

      // @keyframes ld-cycle
      // {
      //   0% {
      //     animation-timing-function: cubic-bezier(0.3333,0.3333,0.6667,0.6667);
      //     transform: rotate(0deg);
      //   }
      //   100% {
      //     transform: rotate(360deg);
      //   }
      // }
    }

    .close {
      position: absolute;
      top: 12px;
      right: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: px2rem(48);
      height: px2rem(48);
      border-radius: 8px;
      cursor: pointer;
      &:hover {
        background-color: #ffffff33;
      }
      img {
        width: px2rem(24);
        height: px2rem(24);
      }
    }

    .header {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 px2rem(100) px2rem(12) px2rem(120);

      .select-all {
        display: flex;
        align-items: center;
        column-gap: 12px;
        font-size: px2rem(20);
        cursor: pointer;
        .bar {
          width: 1px;
          height: 20px;
          background: #ffffff80;
        }
      }
      .actions {
        display: flex;
        align-items: center;
        column-gap: 12px;
        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: px2rem(36);
          height: px2rem(36);
          border-radius: 6px;
          &:hover {
            background-color: #ffffff33;
          }
          img {
            width: px2rem(24);
            height: px2rem(24);
            
            &.disabled {
              @include filter_FFF_20;
              cursor: not-allowed;
            }
          }
        }
      }
    }

    .content {
      position: relative;
      height: calc(100% - px2rem(130));
      margin-right: px2rem(50);
      padding: 0 px2rem(25) 0 px2rem(65);
      overflow: overlay;
      &.query-by-time {
        height: calc(100% - px2rem(220));
      }
      .card {
        display: flex;
        background: #4A5C78;
        border-radius: px2rem(30);
        margin-bottom: px2rem(10);
      }
    }

    .footer:deep {
      display: flex;
      justify-content: center;
      margin-top: px2rem(26);
      .select-selected {
        img {
          @include filter_FFF;
        }
      }
    }
  }

  .video-check {
    width: 12%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .video-img {
    width: 23%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    img[lazy=error] {
      width: 60%;
      height: 60%;
    }
    .icon-play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: px2rem(70);
      height: px2rem(70);
      cursor: pointer;
      &:hover {
        @include filter_FFF;
      }
    }
  }

  .video-info {
    width: 43%;
    padding: px2rem(30) px2rem(24);
    position: relative;
    .info {
      display: flex;
      align-items: center;
      column-gap: 12px;
      font-size: px2rem(18);

      img {
        width: px2rem(20);
        height: px2rem(20);
      }
      &:not(:last-child) {
        margin-bottom: px2rem(20);
      }
    }
    .download {
      position: absolute;
      right: px2rem(24);
      bottom: px2rem(8);

      &:has(.fDownLoad) {
        cursor: pointer;
      }

      &:has(.hlsDownLoad) {
        img {
          @include filter_C1C1C1;
        }
      }
    }
  }

  .video-keep {
    width: 22%;
    border-left: 1px solid #ffffff33;
    font-size: px2rem(18);
    cursor: pointer;
    .item {
      min-height: px2rem(60);
      text-align: center;
      padding: px2rem(20) 0;
      & div:first-child {
        font-size: px2rem(16);
        color: #ffffff80;
      }
      &:not(:last-child) {
        border-bottom: 1px solid #ffffff33;
      }
      &:last-child {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          margin-right: 8px;
        }
      }
    }
    
  }
}
</style>