<template>
  <portal to="ai-box-modal">
    <div class="wrap-ai-box"></div>
    <div class="container-ai-box">
      <div class="close-icon" @click="closeModal">
        <img src="@/assets/icons/clear.svg" alt="">
      </div>
      <TaskList />
      <component :is="currentComponent" class="content" />
      <AiboxPortal v-if="showAiboxPortal" />
    </div>
  </portal>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'

export default {
  name: 'AiBox',
  components: { 
    TaskList: () => import('@/components/AiBox/TaskList.vue'),  
    RecgDevice: () => import('@/components/AiBox/RecgDevice.vue'),
    RecgAibox: () => import('@/components/AiBox/RecgAibox.vue'),
    AiboxPortal: () => import('@/components/AiBox/AiboxPortal.vue'), 
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState('aibox', ['aiMode', 'aiRunOn', 'isEdit', 'taskId', 'showAiboxPortal']),
    currentComponent() {
      if (this.aiRunOn === 'device') return 'RecgDevice'
      else if (this.aiRunOn === 'aibox') return 'RecgAibox'
      return null
    },
  },
  mounted() {
    this.getUserTreeList({ public: 0 })
    this.getDeviceTreeList({ public: 0 })
    this.getAiBoxes()
    this.getAiBoxTasks()
  },
  beforeDestroy() {
    this.resetState()
  },
  methods: {
    ...mapMutations('aibox', ['updateShowAiBox', 'updateParamAiboxPortal', 'updateShowAiboxPortal', 'resetState']),
    ...mapActions('aibox', ['getAiBoxes', 'getAiBoxTasks']),
    ...mapActions('account', ['getUserTreeList', 'getDeviceTreeList']),
    closeModal() {
      if (this.isEdit) {
        this.updateParamAiboxPortal({ info: 'cancelEdit', action: 'closeModal' })
        this.updateShowAiboxPortal(true)
      } else
        this.updateShowAiBox(false)
    },
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.wrap-ai-box {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000000b3;
  z-index: 6;
}

.container-ai-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 96.6%;
  height: 92.6%;
  background: #282942;
  border-radius: 4px;
  z-index: 6;
  display: flex;
}

.close-icon {
  width: 54px;
  height: 54px;
  background: #6E7D93;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -24px;
  top: -27px;
  z-index: 7;
  cursor: pointer;
}

.content {
  flex: 1;
  height: 100%;
}

.fade-enter-active {
  transition: all 5.5s;
}

.fade-leave {
  opacity: 0;
}

</style>