<template>
  <div class="ptz-control-btn">
    <template v-if="isSupportPtz">
      <div class="bar" :class="{ 'ptz-disable': ptzDisable }"></div>
      <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :content="$t('video_ctrlbtn_ptz')/*遠端鏡頭控制*/" placement="top">
        <div class="control-btn btn-ptz"  :class="{ 'show-ptz': showPtzPanel, 'ptz-disable': ptzDisable }" @click="showPTZ">
          <img src="@/assets/icons/PTZ.svg" alt="">
        </div>
      </el-tooltip>
      <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :content="$t('video_ctrlbtn_time_axis')/*歷史影音*/" placement="top">
        <div class="control-btn btn-time" :class="{ 'show-time': !showPtzPanel, 'ptz-disable': ptzDisable }" @click="showTime">
          <img src="@/assets/icons/time.svg" alt="">
        </div>
      </el-tooltip>
    </template>
    <div class="control-btn info" @mouseover="showPtzInfo" @mouseout="hidePtzInfo">
      <img src="@/assets/icons/info.svg" alt="">
      <div v-if="bShowPtzInfo" class="popover" :class="{ 'popover-new': bitrate }">
        <div class="item" v-for="(value, key, index) in ptzData" :key="index">
          <div class="title">{{ key }}</div>
          <div class="data"><pre>{{ value }}</pre></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'

export default {
  name: 'PtzControlBtn', 
  data() {
    return {
      bShowPtzInfo: false,
      bShowExtendedPtzInfo: false,
    }
  },
  computed: {
    ...mapState(['liveMode', 'TopMenuHeightLevel', 'permissionV2']),
    ...mapState('ptz', [
      'showPtzPanel',
      'mirror',
      'pt',
      'cam',
      'version',
      'resolution',
      'codecs',
      'framerate',
      'bitrate',
      'audioBitrate',
      'protocol',
      'dataRxBitrate',
      'videoSrcBitrate',
      'videoSrcFramerate',
      'videoInBitrate',
      'videoInFramerate',
      'videoRxBitrate',
      'videoRxFramerate',
      'videoRxSrcCorrelHistory',
      'videoRxInCorrelHistory',
    ]),
    ...mapGetters('ptz', ['isSupportPtz']),
    ptzData() {
      // 雲台資訊：excellent 球機 / poor 箱型
      let pt = this.pt === 'excellent' ? '球機' : this.pt === 'poor' ? '箱型' : ''
      let ptzData = {}
      if (this.pt) this.$set(ptzData, "-雲台資訊", pt)
      if (this.cam) this.$set(ptzData, "-攝影機資訊", this.cam)
      if (this.version) this.$set(ptzData, "-版本", this.version)
      if (this.codecs) this.$set(ptzData, "-Codecs", this.codecs)
      if (this.resolution) this.$set(ptzData, "-Video resolution", this.resolution)
      if (this.bitrate) this.$set(ptzData, "-Video bitrate", this.bitrate)
      if (this.framerate) this.$set(ptzData, "-Video frame rate", this.framerate)
      if (this.audioBitrate) this.$set(ptzData, "-Audio bitrate", this.audioBitrate)
      if (this.protocol) this.$set(ptzData, "-Network protocol", this.protocol)
      if (this.bShowExtendedPtzInfo) {
        if (this.dataRxBitrate && this.dataRxBitrate.average) {
          this.$set(ptzData, "-Rawdata bitrate history", `${this.dataRxBitrate.average} (${this.dataRxBitrate.history})`)
        }

        let videoBitrateHistory = ''
        if (this.videoSrcBitrate && this.videoSrcBitrate.average) {
          videoBitrateHistory += `S: ${this.videoSrcBitrate.average} (${this.videoSrcBitrate.history})\n`
        }
        if (this.videoInBitrate && this.videoInBitrate.average) {
          videoBitrateHistory += `I: ${this.videoInBitrate.average} (${this.videoInBitrate.history})\n`
        }
        if (this.videoRxBitrate && this.videoRxBitrate.average) {
          videoBitrateHistory += `R: ${this.videoRxBitrate.average} (${this.videoRxBitrate.history})\n`
        }
        if (this.videoRxSrcCorrelHistory) {
          videoBitrateHistory += `Q-RS: ${this.videoRxSrcCorrelHistory}\n`
        }
        if (this.videoRxInCorrelHistory) {
          videoBitrateHistory += `Q-RI: ${this.videoRxInCorrelHistory}\n`
        }
        if (videoBitrateHistory) {
          this.$set(ptzData, "-Video bitrate history", videoBitrateHistory)
        }

        let videoFramerateHistory = ''
        if (this.videoSrcFramerate && this.videoSrcFramerate.average) {
          videoFramerateHistory += `S: ${this.videoSrcFramerate.average} (${this.videoSrcFramerate.history})\n`
        }
        if (this.videoInFramerate && this.videoInFramerate.average) {
          videoFramerateHistory += `I: ${this.videoInFramerate.average} (${this.videoInFramerate.history})\n`
        }
        if (this.videoRxFramerate && this.videoRxFramerate.average) {
          videoFramerateHistory += `R: ${this.videoRxFramerate.average} (${this.videoRxFramerate.history})\n`
        }
        if (videoFramerateHistory) {
          this.$set(ptzData, "-Video framerate history", videoFramerateHistory)
        }
      }
      return ptzData
    },
    ptzDisable() {
      return !this.liveMode || !this.isSupportPtz || this.permissionV2.deviceCtrl < 2
    }
  },
  methods: {
    ...mapMutations('ptz', ['updateShowPtzPanel']),
    showPTZ() {
      if (this.ptzDisable) return
      if (this.TopMenuHeightLevel == 3)
        this.updateShowPtzPanel(true)
    },
    showTime() {
      if (this.ptzDisable) return
      this.updateShowPtzPanel(false)
    },
    showPtzInfo(event) {
      this.bShowPtzInfo = true
      this.bShowExtendedPtzInfo = (event && event.ctrlKey && event.shiftKey)
    },
    hidePtzInfo() {
      this.bShowPtzInfo = false
      this.bShowExtendedPtzInfo = false
    },
  }
}
</script>

<style lang="scss" scoped>
.ptz-control-btn {
  display: flex;
  align-items: center;
}

.control-btn {
  cursor: pointer;
  width: 20px;
  opacity: 0.9;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 6px;
  border-radius: 4px;
  transition: opacity 0.2s ease-out, scale 0.2s ease-out;
  transform: scale(1);
}

.control-btn:not(.show-ptz):not(.show-time):not(.ptz-disable):not(.info):hover {
  opacity: 1;
  transform: scale(1.2);
  background: #ffffff33;
}

.control-btn img {
  width: 20px;
  height: auto;
}

.bar {
  margin-left: px2rem(6);
  margin-right: px2rem(6);
  border: 1px solid $color_FFF_40;
  height: px2rem(24);
}

.ptz-disable {
  opacity: 0.2;
  cursor: default;
}

.btn-ptz {
  background: #afafaf33;
  border-radius: 8px 0 0 8px;
}

.btn-time {
  background: #afafaf33;
  
  border-radius: 0 8px 8px 0;
  margin-right: 4px;
}

.btn-time:hover {
  background: #cdcdcd;
  z-index: 2;
}

.show-time img, .show-ptz img {
  filter: brightness(0) saturate(100%) invert(9%) sepia(29%) saturate(1346%) hue-rotate(189deg) brightness(90%) contrast(95%);
}

.show-time, .show-ptz {
  background: #ffffff;
}

.show-time:hover, .show-ptz:hover {
  background: #ffffff;
  z-index: 2;
}

.info {
  position: relative;
}

.popover {
  position: absolute;
  left: -160px;
  bottom: 36px;
  width: 240px;
  padding: 8px 16px;
  box-sizing: border-box;
  background: #191919;
  border-radius: 8px;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  font-weight: 300;
  border: 1px solid #ffffff80;
}

.popover::after {
  content: '';
  position: absolute;
  left: 170px;
  bottom: -6px;
  width: 10px;
  height: 10px;
  background: #191919;
  transform: rotate(45deg);
  border-right: 1px solid #ffffff80;
  border-bottom: 1px solid #ffffff80;
}

.popover-new {
  left: -480px;
  width: 520px;
}

.popover-new::after {
  left: 490px;
}

.title {
  color: #ffffff80;
}

.data {
  padding-left: 8px;
}

.data pre {
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>