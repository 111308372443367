<template>
  <div class="dashboard-event-modal">
    <div class="header">
      <div class="title-page">
        <div class="title">{{ eventTitle }}</div>
        <el-tooltip popper-class="el-tooltip" effect="dark" :visible-arrow="false" v-delTabIndex :content="$t('history_event_previous_tooltip')/*上一筆(左鍵)*/" placement="bottom">
          <div class="btn" :class="{ disabled: isStopPrev }" @click="setPrevEvent">
            <img src="@/assets/icons/arrow-prev.svg" alt="">
          </div>
        </el-tooltip>
        <el-tooltip popper-class="el-tooltip" effect="dark" :visible-arrow="false" v-delTabIndex :content="$t('history_event_next_tooltip')/*下一筆(右鍵)*/" placement="bottom">
          <div class="btn" :class="{ disabled: isStopNext }" @click="setNextEvent">
            <img src="@/assets/icons/arrow-next.svg" alt="">
          </div>
        </el-tooltip>
      </div>
      <div class="close">
        <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('history_event_back_tooltip')/*關閉(Esc)*/" placement="bottom">
          <div class="btn" @click="closeEventCardDetail">
            <img src="@/assets/icons/clear.svg" alt="">
          </div>
        </el-tooltip>
      </div>
    </div>
    <div class="control">
      <TooltipElement
        :content="$t('history_event_screen_capture') /*擷取畫面*/"
        placement="bottom"
        alwaysShow
      >
        <div class="btn" @click="handleScreenCapture">
          <img src="@/assets/icons/print-screen.svg" alt="" />
        </div>
      </TooltipElement>
      <TooltipElement
        v-if="hasVideo"
        :content="$t('video_download' /*下載影片*/)"
        placement="bottom"
        alwaysShow
      >
        <div v-if="searchLoading" class="btn">
          <RingLoading
            :width="32"
            :height="32"
            :ringWidth="7"
            background="transparent"
          />
        </div>
        <div v-else class="btn" :class="{ disabled: total === 0 }" @click="handleShowVideoPanel">
          <img src="@/assets/icons/download.svg" alt="" />
        </div>
      </TooltipElement>
      <div v-if="hasVideo" class="divider"></div>
      <div v-if="hasVideo" class="switch-group">
        <div 
          class="switch pic" 
          :class="{ active: !isBackPlay }" 
          @click="updateIsBackPlay(false)">
          <img src="@/assets/icons/picture.svg" alt="" />
        </div>
        <div 
          class="switch video" 
          :class="{ active: isBackPlay, disabled: total === 0 }" 
          @click="handleVideoToggle">
          <img src="@/assets/icons/video-play.svg" alt="" />
        </div>
      </div>
      
    </div>
    <div class="container-dashboard-modal" :class="{ 'print-screen': isPrintScreen }">
      <EventDetailFr v-if="eventType === 'fr'" :event="event" />
      <EventDetail v-else :event="event" :type="eventType" />
      <div class="action-aside">
        <div class="top">
          <div v-if="showMapLock" class="action lock" @click="handleMapLock">
            <img
              v-if="isMapDraggable"
              src="@/assets/icons/WebMapCenter.svg"
              alt=""
            />
            <img v-else src="@/assets/icons/WebMapCenter_Locked.svg" alt="" />
          </div>
        </div>
        <div class="bottom">
          <div class="btn">
            <img src="@/assets/icons/Engineer.svg" alt="">
          </div>
          <div v-if="hasMisjudgment" class="btn">
            <EventMisjudgment :event="event" :type="eventType" />
          </div>
        </div>
      </div>
    </div>
    <div 
      v-if="isPrintScreen" 
      :id="printScreenId"
      :key="printScreenId" 
      class="dashboard-screen-shot"
    >
      <div class="title">{{ eventTitle }}</div>
      <template v-if="eventType === 'fr'">
        <EventDetailFr 
          :event="event" 
          :key="printScreenId + '-detail'" 
          :isPrintScreen="isPrintScreen" 
        />
      </template>
      <EventDetail 
        v-else 
        :event="event" 
        :key="printScreenId + '-detail'" 
        :type="eventType" 
        :isPrintScreen="isPrintScreen" 
      />
    </div>

    <div v-if="isPrintScreen" class="is-capturing">
        <RingLoading />
      </div>

    <VideoDownload
      v-if="isShowEventVideoPanel"
      :queryByTime="false"
      :canDelete="false"
      @close="updateIsShowEventVideoPanel(false)"
    />
    <VideoDownload
      v-if="isShowVideoDownloadPanel"
      :queryByTime="true"
      :canDelete="false"
      @close="updateIsShowVideoDownloadPanel(false)"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import html2canvas from 'html2canvas'
import { getEventFilename, getEventTitle } from '@/utils/lib.js'

export default {
  name: 'DashboardEventModal',
  components: { 
    TooltipElement: () => import('@/components/Base/TooltipElement.vue'),
    EventDetailFr: () => import('@/components/Base/EventDetailFr.vue'),
    EventDetail: () => import('@/components/Base/EventDetail.vue'),
    EventMisjudgment: () => import('@/components/Base/EventMisjudgment.vue'),
    VideoDownload: () => import('@/components/Base/VideoDownload.vue'),
    RingLoading: () => import('@/components/Base/RingLoading.vue'),
  },
  data() {
    return {
      clicks: 0,
      isPrintScreen: false,
    }
  },
  computed: {
    ...mapState({ event: 'eventCardDetailObj' }),
    ...mapState(['filteredDisplayEvents']),
    ...mapState('video', [
      'isBackPlay', 
      'isMapDraggable',
      'total', 
      'isShowEventVideoPanel', 
      'isShowVideoDownloadPanel',
      'searchLoading'
    ]),
    isStopPrev() {
      const index = this.filteredDisplayEvents.findIndex(item => item.id === this.event.id)
      return index === 0
    },
    isStopNext() {
      const index = this.filteredDisplayEvents.findIndex(item => item.id === this.event.id)
      return index === this.filteredDisplayEvents.length - 1
    },
    eventType() {
      // fr, lpr, chased(正在追車/追車結束), sos
      if (this.event.uid.startsWith('fr')) return 'fr'
      if (this.event.uid.startsWith('or')) return 'or'
      if (this.event.uid.startsWith('sos')) return 'sos'
      return this.event.chased === 2 || this.event.chased === 3 
        ? 'chased' : 'lpr'
    },
    eventTitle() {
      return getEventTitle(this.eventType)
    },
    hasVideo() {
      return this.total > 0
    },
    hasMisjudgment() {
      return this.eventType !== 'or'
    },
    showMapLock() {
      return (
        this.eventType === 'lpr' ||
        this.eventType === 'or' ||
        this.eventType === 'chased' ||
        this.eventType === 'sos'
      )
    },
    printScreenId() {
      return `dashboard-screen-shot-${this.eventType}-${this.event.id}`
    }
  },
  created() {
    window.addEventListener('keyup', this.onKeyUp)
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.onKeyUp)
  },
  methods: {
    ...mapMutations(['updateShowEventCardDetail']),
    ...mapMutations('video', [
      'updateIsShowEventVideoPanel', 
      'updateIsShowVideoDownloadPanel', 
      'updateIsBackPlay',
      'updateIsMapDraggable',
    ]),
    closeEventCardDetail() {
      this.updateShowEventCardDetail(false)
    },
    // 與事件列表點擊事件卡片時的行為一致，這邊是將事件資料(eventObj)傳入
    openDetailCard(eventObj) {
      this.clicks++
      if (this.clicks === 1) {
        // 0.5秒後重設 clicks = 0, 避免使用者短時間快速點擊
        this.clickTimer = setTimeout(() => {
          this.clicks = 0
        }, 500)

        this.$bus.$emit('closePopUpMenu')
        this.$store.dispatch('handleEventModal', eventObj)

        if (eventObj.chasing == 1 || eventObj.uid.startsWith('sos')) {
          this.$bus.$emit(
            'sosCall',
            'group',
            eventObj.user.id,
            eventObj.groupId
          )
        }
      }
    },
    setPrevEvent() {
      if (this.isStopPrev) return
      let index = this.filteredDisplayEvents.findIndex(item => item.id === this.event.id)
      this.openDetailCard(this.filteredDisplayEvents[index - 1])
    },
    setNextEvent() {
      if (this.isStopNext) return
      let index = this.filteredDisplayEvents.findIndex(item => item.id === this.event.id)
      this.openDetailCard(this.filteredDisplayEvents[index + 1])
    },
    onKeyUp(e) {
      e.preventDefault()
      if (e.keyCode === 27) this.closeEventCardDetail() // Esc
      if (e.keyCode === 37) this.setPrevEvent() // Left
      if (e.keyCode === 39) this.setNextEvent() // Right
    },
    handleVideoToggle() {
      if (this.total === 0) return
      this.updateIsBackPlay(true)
    },
    handleShowVideoPanel() {
      if (this.total === 0) return
      this.updateIsShowEventVideoPanel(true)
    },
    /* 取得畫面截圖放於剪貼簿 */
    getScreenShot(){
      let src = document.getElementById(this.printScreenId)
      html2canvas(src, { useCORS: true, allowTaint: true, })
        .then(function(canvas) {
          canvas.toBlob(function(blob) {
            navigator.clipboard
              .write([
                new window.ClipboardItem(
                  Object.defineProperty({}, blob.type, {
                    value: blob,
                    enumerable: true
                  })
                )
              ])
              .then(function() {
              })
          })
        })
    }, 
    handleScreenCapture() {
      this.isPrintScreen = true
      setTimeout(() => {
        this.screenCapture()
      }, 2000)
    },
    screenCapture() {
      this.$nextTick(() => {
        let src = document.getElementById(this.printScreenId)
        html2canvas(src, { 
          useCORS: true, 
          allowTaint: true, 
          backgroundColor: '#282942',
          scrollX: 0,
          scrollY: 0,
          windowWidth: src.scrollWidth,
          windowHeight: src.scrollHeight,
        })
        .then(canvas => {
          /**
           * 擷取畫面有時會發生CSS失效導致截圖跑版
           * 這邊檢查截圖大小是否與畫面大小相差太大，若是則提示使用者進行reload，再進行截圖
           * TODO: 未來可考慮針對截圖頁面進行重構(頁面單純顯示圖片與資料，移除不必要的元件與功能)
           */
          if (this.checkScreenShotSize(src.scrollWidth, src.scrollHeight, canvas.width, canvas.height) === false) {
            this.$notify({ 
              title: this.$t('search_hint'), 
              message: this.$t('history_event_screen_capture_info'), 
              type: 'warning' 
            })
            this.isPrintScreen = false
            return
          }
          canvas.toBlob(blob => {
            const link = document.createElement('a')
            const imgURL = URL.createObjectURL(blob)
            link.download = getEventFilename(this.event)
            link.href = imgURL
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            URL.revokeObjectURL(imgURL)
            this.isPrintScreen = false
          })
        })
        .catch(err => {
          this.isPrintScreen = false
          console.log(err)
        })
      })
    },
    checkScreenShotSize(targetW, targetH, canvasW, canvasH) {
      const diffW = Math.abs(canvasW - targetW) / targetW
      const diffH = Math.abs(canvasH - targetH) / targetH
      // mac 螢幕截圖 canvasW 會比 targetW 大1倍(diffW = diffH = 1)
      // 正常狀況，diffW = diffH = 0, 但有時會有誤差，所以設定誤差範圍
      if (diffW === diffH || (diffW < 0.002 && diffH < 0.002)) {
        return true
      }
      return false
    },
    handleMapLock() {
      this.updateIsMapDraggable(!this.isMapDraggable)
    },
  }
}
</script>

<style lang="scss" scoped>
.dashboard-event-modal {
  position: absolute;
  top: 0;
  left: 50px;
  right: 390px;
  bottom: 0;
  background: #282942;
  color: #ffffff;
  padding: 8px 0px 0px 20px;
  display: flex;
  flex-direction: column;
  z-index: 4;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background: #ffffff33;
  }
  &.disabled {
    cursor: not-allowed;
    @include filter_FFF_20;
  }

  img {
    width: 20px;
    height: 20px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 8px;
  margin-bottom: 12px;
  .title-page {
    display: flex;
    align-items: center;
    column-gap: 12px;
    .title {
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
    }
  }
}

.control {
  display: flex;
  align-items: center;
  column-gap: 12px;
  padding-right: 8px;
  margin-bottom: 12px;

  .divider {
    width: 1px;
    height: 36px;
    background: #ffffff80;
  }

  .switch-group {
    display: flex;
    align-items: center;
  }

  .switch {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 36px;
    border-radius: 8px;
    background: #ffffff33;
    cursor: pointer;
    &:hover {
      background: #ffffff50;
    }
    &.active {
      background: #ffffff80;
      img {
        @include filter_FFF;
      }
    }
    &.disabled {
      cursor: not-allowed;
      @include filter_FFF_50;
    }

    &.pic {
      border-radius: 8px 0 0 8px;
    }

    &.video {
      border-radius: 0 8px 8px 0;
    }

    img {
      width: 24px;
      height: 24px;
      @include filter_FFF_20;
    }
  }
}

.container-dashboard-modal {
  width: 100%;
  height: calc(100% - 116px);
  display: flex;
  column-gap: 8px;
  background: #282942;
  padding-bottom: 20px;
  // z-index: 1;
}

.action-aside {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 8px;
  margin-top: 45px;
  padding-right: 8px;
  margin-bottom: 20px;

  .action {
    width: 36px;
    height: 36px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.lock {
      background: #ffffff33;
      &:hover {
        background: #ffffff80;
      }
    }

    img {
      width: 22px;
      height: 22px;
      @include filter_FFF;
    }
    .engineer-disabled {
      @include filter_FFF_50;
    }
  }
  .mis-judgment {
    width: 22px;
    height: 22px;
  }
  img {
    @include filter_FFF_50;
  }
}

.dashboard-screen-shot {
  position: absolute;
  left: 0px;
  top: 116px;
  width: 1200px;
  height: auto;
  background: #282942;
  padding: 20px;
  z-index: -1;

  .title {
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    color: #ffffff;
    margin-bottom: 10px;
  }
}

.is-capturing {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
</style>