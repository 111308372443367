<template>
  <div class="container-video" :class="{'keep': !isMuted || isCalling}" ref="containerVideo">
    <div v-if="!mseUrl" class="no-stream-info" @dblclick.stop="noStreamDblClick">
      <span>{{ $t('no_streaming') }}</span>
    </div>
    <video
      v-else
      class="video"
      ref="video"
      @timeupdate="onTimeUpdate"
      @click.prevent=""
      @dblclick.stop="onDubClick"
    ></video>
    <el-tooltip
      v-if="videoTitle"
      popper-class="el-tooltip"
      effect="dark"
      v-delTabIndex
      :visible-arrow="false"
      :content="videoTitle"
      placement="bottom"
      :disabled="!showTitleToolTip"
    >
      <div class="video-title" :class="[titleStyle]" @dblclick.stop="onDubClick">
        <img v-if="mseUrl" src="@/assets/icons/live.svg" alt="" />
        <img v-else src="@/assets/icons/vod.svg" alt="" />
        <span @mouseenter.stop="onTitleMouseEnter" @mouseleave.stop="onTitleMouseLeave">
          {{ videoTitle }}
        </span>
      </div>
    </el-tooltip>
    <div v-if="mseUrl" class="controls">
      <div class="left">
        <div class="icon-phone" @click.stop="changePhone">
          <img v-if="isCalling" src="@/assets/icons/user-calling-on.svg" />
          <img v-else src="@/assets/icons/phone.svg" />
        </div>
        <div class="icon-mute" @click.stop="changeMuted">
          <img v-if="isMuted" src="@/assets/icons/volume-x.svg" />
          <img v-else src="@/assets/icons/sound.svg" />
        </div>
        <input
          type="range"
          min="0"
          max="1"
          class="volume"
          :style="volumeStyle"
          step="0.01"
          v-model="volume"
          @mousedown.stop="volumeChangeStart"
          @input.stop="volumeChange"
          @mouseup.stop="volumeChangeEnd"
        />
        <!-- <div class="time">
          <span>{{ timeElapsed.minutes }}:{{ timeElapsed.seconds }} </span>
        </div> -->
      </div>
      <div class="right">
        <div class="control-btn" @click.stop="extendToFullscreen">
          <img src="@/assets/icons/fullscreen.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mse from '@/api/mse.js'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'Video',
  props: {
    index: Number,
    userId: String,
    mseUrl: String,
    videoTitle: String,
    callingUserIdx: {
      type: Number,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      player: null,
      pause: false,
      isMuted: true,
      oldIsMuted: true, // 為了處理通話中,要保存原有的 isMuted 狀態
      isCalling: false,
      // isDisabled: false, // 通話中不能調整音量
      volume: 0,
      preVolume: 0.5,
      timeElapsed: {
        minutes: '--',
        seconds: '--'
      },
      fullScreenMode: false,
      showTitleToolTip: false
    }
  },
  computed: {
    ...mapState([
      'callMode',
      'callCmd',
      'videoCall',
      'isPageVisible',
      'videoViewMode',
      'TopMenuHeightLevel'
    ]),
    titleStyle() {
      let style = null
      // 4/4
      if (this.TopMenuHeightLevel === 4) {
        // 2x2
        if (this.videoViewMode === 2) {
          style = 'two'
        } // 3x3
        else if (this.videoViewMode === 3) {
          style = 'three'
        } // 4x4
        else if (this.videoViewMode === 4) {
          style = 'four'
        }
      }

      // console.log(`[titleStyle] style:`, style)
      return style
    },
    volumeStyle() {
      return {
        '--volume': this.volume * 100 + '%'
      }
    },
    isAllowCall() {
      return this.callMode === 'off'
    }
  },
  watch: {
    isPageVisible() {
      if (this.isPageVisible) this.playVideo() // 網頁若切換到前景則 reset video
      else this.pauseVideo()                    // 若切換到背景則 pause video
    },
    mseUrl(nVal, oVal) {
      if (nVal !== oVal) {
        this.pauseVideo()
        this.init()
      }
      // if (this.mseUrl) this.playVideo()
      // else this.pauseVideo()
    },
    videoCall(value, oldValue) {
      if (oldValue == true && value == false) this.isCalling = false
    },
    volume() {
      this.setMuted()
    },
    callMode() {
      // console.log(`[Mutiple.Video.W.callMode] ${this.userId} Mode, Cmd:`, this.callMode, this.callCmd)
      if (!this.userId || !this.index) return
      // 掛斷
      if (this.callMode === 'off') {
        if (this.callCmd === 'hangup') {
          if (this.index === this.callingUserIdx) {
            // console.log('恢復聲音')
            if (!this.oldIsMuted) {
              this.changeMuted()
            }
            this.isCalling = false
            this.$notify({
              type: 'warning',
              title: this.$t('hint'),
              message: this.$t('top_bar_calling_volume_reverse')
            })
            this.$emit('muteAll', null)
            this.isCalling = false
          } else {
            if (!this.oldIsMuted) {
              this.changeMuted()
              this.oldIsMuted = this.isMuted
            }
          }
        }
      }
    },
    callCmd() {
      if (!this.userId || !this.index) return

      // 撥話中
      if (this.callCmd === 'call') {
        console.log(`撥話中`)
        // TODO：需要知道是哪個設備播電話給WEB
        // this.$emit('muteAll', this.index) // 更新 callingUserIdx
        // this.$nextTick(() => {
        //   if (this.index === this.callingUserIdx) {
        //     this.isCalling = true
        //   }
        // })
      }
      // 接聽
      if (this.callCmd === 'answer') {
        this.oldIsMuted = this.isMuted // 保留原有 靜音 狀態
        // console.log('關掉聲音')
        if (!this.oldIsMuted) {
          this.changeMuted()
        }

        if (this.index === this.callingUserIdx) {
          // console.log(`${this.userId} 接聽`)
          // this.isDisabled = true
          this.$notify({
            type: 'warning',
            title: this.$t('hint'),
            message: this.$t('top_bar_calling_volume_off')
          })
        }
      }
    }
  },
  mounted() {
    try {
      this.init()
    } catch (err) {
      console.error(`Video mounted err:`, err)
    }
  },
  beforeDestroy() {
    this.pauseVideo()
  },
  methods: {
    ...mapMutations(['updateSingleUrlUserID', 'updateVideoViewMode']),
    init() {
      this.volume = 0
      this.playVideo()
      this.setMuted()
    },
    setMuted() {
      this.isMuted = this.volume == 0 ? true : false
      this.$nextTick(() => {
        if (this.$refs.video) {
          this.$refs.video.muted = this.isMuted
        }
      })
    },
    onTitleMouseEnter(evt) {
      // this.showTitleToolTip = true
      // 檢查元素的實際高度和捲動高度是否不同。如果元素的實際高度大於捲動高度，則表示文字被截斷
      const element = evt.target
      this.showTitleToolTip = element.scrollWidth > element.clientWidth
    },
    onTitleMouseLeave() {
      this.showTitleToolTip = false
    },
    noStreamDblClick() {
      if (this.videoTitle) {
        this.onDubClick()
      }
    },
    onDubClick(/*event*/) {
      this.updateSingleUrlUserID(this.userId)
      this.updateVideoViewMode(1)
    },
    playVideo() {
      if (!this.mseUrl) return

      this.$nextTick(() => {
        if (this.$refs.video) {
          this.player = new mse(this.$refs.video, this.mseUrl)
          this.player.startup()
          this.$refs.video.play()
        }
      })
    },
    pauseVideo() {
      if (this.player) this.player?.close()
      this.player = null
    },
    changeMuted() {
      this.isMuted = !this.isMuted
      if (this.isMuted) {
        this.preVolume = this.volume
        this.volume = 0
      } else {
        this.volume = this.preVolume
      }

      this.$nextTick(() => {
        if (this.$refs.video) {
          this.$refs.video.muted = this.isMuted
          this.$refs.video.volume = this.volume
        }
      })
    },
    volumeChange() {
      // this.$nextTick(() => {
      this.$refs.video.volume = this.volume
      // })
      this.setMuted()
    },
    volumeChangeStart() {
      this.$emit('test', true)
      // this.$emit('stopdrag', true)
    },
    volumeChangeEnd() {
      // this.$emit('stopdrag', false)
      this.$emit('test', false)
    },
    changePhone() {
      const newIsCalling = !this.isCalling
      if (newIsCalling) {
        // this.$bus.$emit('videoCall', 'user', this.videoTitle, '1234') // hardcode 的 devide.index('1234') 造成 poreal 收不到
        this.$bus.$emit('videoCall', 'user', this.videoTitle, this.index)
        this.$store.commit('updateVideoCall', true)
      } else {
        this.$bus.$emit('videoHandOff')
        this.$store.commit('updateVideoCall', false)
      }

      if (this.isAllowCall) {
        this.isCalling = newIsCalling
      }

      this.$emit('muteAll', this.index) // 更新 callingUserIdx
    },
    onTimeUpdate() {
      if (this.$refs.video) {
        let videoCurrentSeconds = Math.round(this.$refs.video.currentTime)
        this.timeElapsed = this.formatTime(videoCurrentSeconds)
      }
    },
    formatTime(timeInSeconds) {
      const result = new Date(timeInSeconds * 1000).toISOString().substr(11, 8)
      return {
        minutes: result.substr(3, 2),
        seconds: result.substr(6, 2)
      }
    },
    extendToFullscreen() {
      if (!this.fullScreenMode) {
        this.$refs.containerVideo.webkitRequestFullScreen()
      } else {
        document.webkitExitFullscreen()
      }
      this.fullScreenMode = !this.fullScreenMode
    },
  }
}
</script>

<style lang="scss" scoped>
$VideoTitleH: px2rem(40);

* {
  box-sizing: border-box;
}
.container-video {
  position: relative;
  width: 100%;
  height: 100%;

  &:hover .controls,
  &.keep .controls {
    opacity: 1;
  }
}

.no-stream-info {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 1rem;
  color: $color_FFF_50;
  background-color: $color_black;

  span {
    width: 100%;
    text-align: center;
  }
}

.video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: $color_black;
}

/* hide original video control bar */
video::-webkit-media-controls-enclosure {
  display: none;
}

.video-title {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: flex-end;
  padding: 0.5rem;
  width: 100%;
  height: $VideoTitleH;

  color: $color_FFF;
  background-color: $color_black_40;

  img {
    margin-right: 0.5rem;
    height: px2rem(24);
    width: px2rem(24);
  }

  span {
    font-size: 1rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; // 點點點
  }

  &.two {
    height: calc($VideoTitleH + 100vh * 0.023);
  }
  &.three {
    height: calc($VideoTitleH + 100vh * 0.015);
  }
  &.four {
    height: calc($VideoTitleH + 100vh * 0.007);
  }
}

.controls {
  position: absolute;
  left: 0; // px2rem(6);
  bottom: 0; // px2rem(6);
  padding-top: px2rem(3);
  padding-right: px2rem(6);
  padding-bottom: px2rem(3);
  padding-left: px2rem(6);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  transition: opacity 0.1s ease-in;
  opacity: 0;
  background-color: $color_000_40;
  // background-color: #00f;

  .left {
    display: flex;
    align-items: center;
  }
}

.icon-phone {
  width: 1rem;
  margin-right: px2rem(10);
  &:disabled {
    opacity: 0.2;
  }
}

.icon-mute {
  width: px2rem(24);
  height: px2rem(20);
}

img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

img:hover {
  transform: scale(1.2);
}

.volume {
  -webkit-appearance: none;
  display: inline-block;
  width: 20%;
  height: 0.5em;
  margin-left: px2rem(12);
  position: relative;
  /* left: -10px; */
  border-radius: 5px;
  @include volume_color(--volume);
}

.volume::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.1s;
  background-color: $color_FFF;
  position: relative;
}

.time {
  color: $color_FFF;
  margin-left: px2rem(10);
}

.control-btn {
  cursor: pointer;
  width: 1rem;
  opacity: 0.9;
  display: flex;
  align-items: center;
  // margin-right: 20px;
}

// .control-btn:hover {
//   opacity: 1;
// }

.control-btn img {
  width: 100%;
  height: 100%;
}
</style>
