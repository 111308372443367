<template>
  <div 
    class="wrap-ptz-panel" 
    v-loading="loading" 
    element-loading-background="rgba(0, 0, 0, 0.85)"
  >
    <div class="wrap-move-step">
      <MoveStep />
      <div class="wrap-set-step">
        <div class="title">{{ $t('ptz_move_speed')/*移動速度*/ }}</div>
        <div class="set-step">
          <div class="minus-plus" @click="setMoveStep(-1)">
            <img src="@/assets/icons/minus.svg" alt="">
          </div>
          <div class="step">{{ step }}</div>
          <div class="minus-plus" @click="setMoveStep(1)">
            <img src="@/assets/icons/plus.svg" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="sliders">
      <div class="slider">
        <div class="left">
          <div class="label">{{ $t('ptz_zoom') }}</div>
          <div class="items">
            <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :content="$t('ptz_tooltip_zoom_out')/*縮小*/" placement="top">
              <div class="item" @click="runZoomStep(-1)">
                <img src="@/assets/icons/zoom-out.svg" alt="">
              </div>
            </el-tooltip>
            <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :content="$t('ptz_tooltip_zoom_in')/*放大*/" placement="top">
              <div class="item" @click="runZoomStep(1)">
                <img src="@/assets/icons/zoom-in.svg" alt="">
              </div>
            </el-tooltip>
          </div>
        </div>
        <div class="right">
          <div class="speed-value">
            <span>{{ zoomStep }}</span>
          </div>
          <InputRange :min="1" :max="7" v-model="zoomStepVal" />
        </div>
      </div>
      <div class="slider">
        <div class="camera-param">
          <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :content="tooltipFlip" placement="top">
            <div class="item" :class="{ 'ptz-active': flip }" @click="setFlip">
              <img src="@/assets/icons/flip.svg" alt="">
            </div>
          </el-tooltip>
          <div class="label">{{ $t('ptz_vertical_mirror')/*垂直鏡像*/ }}</div>
        </div>
        <div class="camera-param">
          <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :content="tooltipMirror" placement="top">
            <div class="item" :class="{ 'ptz-active': mirror }" @click="setMirror">
              <img src="@/assets/icons/mirror.svg" alt="">
            </div>
          </el-tooltip>
          <div class="label">{{ $t('ptz_horizontal_mirror')/*水平鏡像*/ }}</div>
        </div>
        <div class="camera-param">
          <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :content="tooltipIR" placement="top">
            <div class="item" @click="setIcrMode">
              <img :src="icrModeIcon" alt="">
            </div>
          </el-tooltip>
          <div class="label">{{ $t('ptz_infrared')/*紅外線*/ }}</div>
        </div>
        <div class="camera-param">
          <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :content="tooltipSensitivity" placement="top">
            <div class="item" @click="setHighSensitivity">
              <img :src="highSenseIcon" alt="">
            </div>
          </el-tooltip>
          <div class="label">{{ $t('ptz_high_sensitivity')/*高感度*/ }}</div>
        </div>
      </div>
      <div class="slider">
        <div class="left">
          <div class="label">{{ $t('ptz_focus') }}</div>
          <div class="items">
            <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :content="$t('ptz_tooltip_auto_focus')/*自動對焦*/" placement="top">
              <div class="item" :class="{ 'ptz-active': autoFocus }"
                @click="setAutoFocus">
                <img src="@/assets/icons/AF_normal.svg" alt="">
              </div>
            </el-tooltip>
            <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :content="$t('ptz_tooltip_near_focus')/*近焦*/" placement="top">
              <div class="item" :class="{ disabled: autoFocus }" @click="runFocusStep(-1)">
                <img src="@/assets/icons/close-focus.svg" alt="">
              </div>
            </el-tooltip>
            <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :content="$t('ptz_tooltip_far_focus')/*遠焦*/" placement="top">
              <div class="item" :class="{ disabled: autoFocus }" @click="runFocusStep(1)">
                <img src="@/assets/icons/far-focus.svg" alt="">
              </div>
            </el-tooltip>
          </div>
        </div>
        <div class="right">
          <div class="speed-value" :class="{ disabled: autoFocus }">
            <span>{{ focusStep }}</span>
          </div>
          <InputRange :min="1" :max="7" v-model="focusStepVal" :disabled="autoFocus" />
        </div>
      </div>
    </div>
    <div v-if="pt === 'excellent'" class="preset-list">
      <div class="title">{{ $t('ptz_preset') }}</div>
      <div class="wrap-preset-items">
        <div v-for="(item, index) in presetData" :key="index" 
          class="preset-item" :class="{ active: index === presetIndex }"
          @mouseover="onMouseOver(index)"
          @click="setActivePresetItem(index)">
          <input ref="presetName" type="text" v-model="item.name" :readonly="!bPositionEdit" :placeholder="$t('ptz_preset_placeholder')">
          <div v-if="index === presetIndex && bPositionEdit" class="save-btn" @click.stop="savePositionEdit(index)">
            <img :src="confirmIcon" alt=""><span class="save-txt">{{ $t('save')/*儲存*/ }}</span>
          </div>
          <div v-else-if="index === onMouseIndex" class="actions">
            <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :content="$t('edit')/*編輯*/" placement="top">
              <div class="icon" @click.stop="setPositionEdit(index)">
                <img :src="penIcon" alt="">
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import MoveStep from './PtzPanel/MoveStep.vue'
import InputRange from './PtzPanel/InputRange.vue'
import { getPtzStatus } from '@/api/index.js'

Math.PI1_8 = Math.PI / 8;
Math.PI3_8 = Math.PI * 3 / 8;
Math.PI5_8 = Math.PI * 5 / 8;
Math.PI7_8 = Math.PI * 7 / 8;

export default {
  name: 'PtzPanel',
  components: { MoveStep, InputRange },
  data() {
    return {
      loading: false,
      presetIndex: null,
      onMouseIndex: null,
      bNameEdit: false,
      bPositionEdit: false,
      minStep: 1,
      maxStep: 10,

      // gamepad related
      gamepadConnected: false,
      gamepadAxisMap: [0, 1, 2], // map to X, Y, Z
      lastGamepadTs: 0,
      lastX: 0,
      lastY: 0,
      lastZ: 0,
      lastPtzTs: 0,
      stopCount: 0,
      gamepadUpdateInterval: null,
      interval: 50,
      ptzRunningCount: 0,
      ptzRunningMax: 2,
      // lastSeqno: 0
    }
  },
  computed: {
    ...mapState('ptz', [
      'pt',
      'step', 
      'zoomStep', 
      'focusStep', 
      'autoFocus', 
      'highSensitivity', 
      'icrMode',
      'flip',
      'mirror',
      'configured',
    ]),
    ...mapGetters('ptz', ['ptzDeviceId']),
    highSenseIcon() {
      return this.highSensitivity ?
        require('@/assets/icons/high_sense.svg') :
        require('@/assets/icons/high_sense_no.svg')
    },
    icrModeIcon() {
      if (this.icrMode === 0) return require('@/assets/icons/IR_no.svg')
      else if (this.icrMode === 1) return require('@/assets/icons/IR.svg')
      else return require('@/assets/icons/IR_auto.svg')
    },
    tooltipFlip() {
      return this.flip ? this.$t('ptz_tooltip_open') : this.$t('ptz_tooltip_close')
    },
    tooltipMirror() {
      return this.mirror ? this.$t('ptz_tooltip_open') : this.$t('ptz_tooltip_close')
    },
    tooltipIR() {
      if (this.icrMode === 0) return this.$t('ptz_tooltip_close')
      else if (this.icrMode === 1) return this.$t('ptz_tooltip_open')
      else return this.$t('ptz_tooltip_auto')
    },
    tooltipSensitivity() {
      return this.highSensitivity ? this.$t('ptz_tooltip_open') : this.$t('ptz_tooltip_close')
    },
    zoomStepVal: {
      get() {
        return this.zoomStep
      },
      set(val) {
        this.updateZoomStep(val)
      }
    },
    focusStepVal: {
      get() {
        return this.focusStep
      },
      set(val) {
        this.updateFocusStep(val)
      }
    },
    presetData: {
      get() {
        return this.configured
      },
      set(array) {
        this.updateConfigured(array)
      }
    },
    confirmIcon() {
      return require('@/assets/icons/Confirm.svg')
    },
    penIcon() {
      return require('@/assets/icons/pen.svg')
    },
  },
  mounted() {
    this.enableGamepadControls()
    this.getDeviceInfo()
    this.runPtzApi() // 作動 高感度 紅外線 影像顛倒 畫面鏡像
  },
  beforeDestroy() {
    this.disableGamepadControls()
    // 移至 PtzControlBtn.vue; 讓有PTZ功能的設備,都可以顯示 PTZ info
    // this.resetPtzData()
  },
  methods: {
    ...mapMutations('ptz', [
      'updateStep', 
      'updateZoomStep', 
      'updateFocusStep', 
      'updateAutoFocus',
      'updateMirror',
      'updateFlip',
      'updateHighSensitivity',
      'updateIcrMode',
      'updateConfigured',
      'updatePtzData',
      'resetPtzData'
    ]),
    async getDeviceInfo() {
      const params = {
        "url": "/api/ptz/status",
        "target": "get",
        "payload": {},
        "sn": this.ptzDeviceId,
      }
      try {
        this.loading = true
        const res = await getPtzStatus(params)
        this.updatePtzData(res.data)
      } catch(error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    onMouseOver(index) {
      this.onMouseIndex = index
    },
    async runZoomStep(type) {
      let zoomStepVal = this.zoomStep * type
      let payload = {"zoomStep": zoomStepVal, "continuous": false }
      const params = {
        "url": "/api/ptz",
        "target": "put",
        "payload": payload,
        "sn": this.ptzDeviceId,
      }
      const res = await getPtzStatus(params)
      if (res.status == 200) {
        //console.log(params, res.data)
        //this.$message.success(`${res.status} ${res.statusText} 變焦已設定！`)
      } else {
        // this.$message.warning(`${res.status} ${res.statusText}！`)
        this.$notify({
          type: 'warning',
          message: `${res.status} ${res.statusText}！`
        })
      }
    },
    async setAutoFocus() {
      this.updateAutoFocus(!this.autoFocus)
      let payload = {"autoFocus": this.autoFocus}
      const params = {
        "url": "/api/ptz",
        "target": "put",
        "payload": payload,
        "sn": this.ptzDeviceId,
      }
      const res = await getPtzStatus(params)
      if (res.status == 200) {
        //console.log(params, res.data)
        //this.$message.success(`${res.status} ${res.statusText} 自動對焦！`)
      } else {
        // this.$message.warning(`${res.status} ${res.statusText}！`)
        this.$notify({
          type: 'warning',
          message: `${res.status} ${res.statusText}！`
        })
      }
    },
    async runFocusStep(type) {
      let focusStepVal = this.focusStep * type
      let payload = {"autoFocus": false, "focusStep": focusStepVal, "continuous": false }
      const params = {
        "url": "/api/ptz",
        "target": "put",
        "payload": payload,
        "sn": this.ptzDeviceId,
      }
      const res = await getPtzStatus(params)
      if (res.status == 200) {
        //console.log(params, res.data)
        //this.$message.success(`${res.status} ${res.statusText} 對焦已設定！`)
      } else {
        // this.$message.warning(`${res.status} ${res.statusText}！`)
        this.$notify({
          type: 'warning',
          message: `${res.status} ${res.statusText}！`
        })
      }
    },
    async getPresetData(id) {
      let payload = {"write": false, "id": id }
      const params = {
        "url": "/api/ptz/config",
        "target": "put",
        "payload": payload,
        "sn": this.ptzDeviceId,
      }
      const res = await getPtzStatus(params)
      if (res.status == 200) {
        this.updatePtzData(res.data)
        this.runPtzApi() // 作動 高感度 紅外線 影像顛倒 畫面鏡像
      } else {
        // this.$message.warning(`${res.status} ${res.statusText}！`)
        this.$notify({
          type: 'warning',
          message: `${res.status} ${res.statusText}！`
        })
      }
    },
    async runPtzApi() { // 作動 高感度 紅外線 影像顛倒 畫面鏡像
      let payload = {}
      this.$set(payload, "highSensitivity", this.highSensitivity)
      this.$set(payload, "icrMode", this.icrMode)
      this.$set(payload, "flip", this.flip)
      this.$set(payload, "mirror", this.mirror)
      const params = {
        "url": "/api/ptz",
        "target": "put",
        "payload": payload,
        "sn": this.ptzDeviceId,
      }
      const res = await getPtzStatus(params)
      if (res.status == 200) {
        //this.$message.success(`${res.status} ${res.statusText} 設定成功！`)
      } else {
        // this.$message.warning(`${res.status} ${res.statusText}！`)
        this.$notify({
          type: 'warning',
          message: `${res.status} ${res.statusText}！`
        })
      }
    },
    async setPresetData(id) {
      let payload = {
        "write": true, 
        "id": id,
      }
      this.$set(payload, "name", this.presetData[id].name)
      this.$set(payload, "step", this.step)
      this.$set(payload, "autoFocus", this.autoFocus)
      this.$set(payload, "highSensitivity", this.highSensitivity)
      this.$set(payload, "icrMode", this.icrMode)
      this.$set(payload, "flip", this.flip)
      this.$set(payload, "mirror", this.mirror)
      const params = {
        "url": "/api/ptz/config",
        "target": "put",
        "payload": payload,
        "sn": this.ptzDeviceId,
      }
      const res = await getPtzStatus(params)
      if (res.status == 200) {
        // this.$message.success(this.$t('ptz_preset_setting_success')) // 點位設定成功！
        this.$notify({
          type: 'success',
          message: this.$t('ptz_preset_setting_success') // 點位設定成功！
        })
      } else {
        // this.$message.warning(this.$t('ptz_preset_setting_fail'))
        this.$notify({
          type: 'warning',
          message: this.$t('ptz_preset_setting_fail') // 點位設定失敗！
        })
      }
    },
    setActivePresetItem(index) {
      if (this.presetIndex == index && this.bPositionEdit) return
      this.bPositionEdit = false
      this.presetIndex = index
      this.getPresetData(index) // 讀取點位資料
    },
    setNameEdit(index) {
      this.$nextTick(() => {
        this.$refs.presetName[index].focus()
      })
    },
    setPositionEdit(index) {
      if (!this.bPositionEdit || this.presetIndex != index) {
        this.setNameEdit(index)
        this.bPositionEdit = true
        this.presetIndex = index
      } 
    },
    savePositionEdit(index) {
      if (this.bPositionEdit) {
        this.setPresetData(index)
        this.bPositionEdit = false
      }
    },
    setMirror() { // 畫面鏡像
      this.updateMirror(!this.mirror)
      this.runPtzApi("mirror", this.mirror)
    },
    setFlip() { // 影像顛倒
      this.updateFlip(!this.flip)
      this.runPtzApi("flip", this.flip)
    },
    setHighSensitivity() { // 高感度
      this.updateHighSensitivity(!this.highSensitivity)
      this.runPtzApi("highSensitivity", this.highSensitivity)
    },
    setIcrMode() { // 0: 關閉紅外線 1: 開啟紅外線 2: 自動
      let tmpCode = this.icrMode + 1
      tmpCode = tmpCode <= 2 ? tmpCode : 0 // 0~2循環
      this.updateIcrMode(tmpCode)
      this.runPtzApi("icrMode", this.icrMode)
    },
    setMoveStep(value) {
      const newVal = this.step + value
      if (newVal < this.minStep || newVal > this.maxStep) return
      this.updateStep(newVal)
    },

    // continuous PTZ commands
    async runPtzContinuous(payload, force) {
      if (this.ptzRunningCount >= this.ptzRunningMax && !force) {
        console.log(`PtzPanel: running >= ${this.ptzRunningMax}; skip this`)
        return
      }

      this.ptzRunningCount++
      try {
        // const tsStart = Date.now()
        // const seqNo = this.lastSeqno = (this.lastSeqno + 1) % 10
        // console.log(`PtzPanel: [${seqNo}-${this.ptzRunningCount}] runPtzContinuous`)

        const params = {
          "url": "/api/ptz",
          "target": "put",
          "payload": payload,
          "sn": this.ptzDeviceId,
        }
        const res = await getPtzStatus(params)
        if (res.status == 200) {
          //console.log(params, res.data)
          //this.$message.success(`${res.status} ${res.statusText}！`)
        } else {
          // this.$message.warning(`${res.status} ${res.statusText}！`)
          this.$notify({
            type: 'warning',
            message: `${res.status} ${res.statusText}！`
          })
        }

        // const elapsedTime = Date.now() - tsStart
        // console.log(`PtzPanel: [${seqNo}] runPtzContinuous completed (${elapsedTime})`)
      } finally {
        this.ptzRunningCount--
      }
    },
    async runMoveContinuous(move, step, stopZoom, force) {
      if (move === 0 && stopZoom)
        console.log(`PtzPanel: stop move & zoom`)
      else
        console.log(`PtzPanel: move continuous, dir=${move}, step=${step}, stopZoom=${stopZoom}`)
      if (move == 5) return // continuous mode does not support Home

      let payload = { "step": step, "move": move, "continuous": true }
      if (stopZoom) payload['zoomStep'] = 0

      await this.runPtzContinuous(payload, force)
    },
    async runZoomContinuous(step, stopMove, force) {
      if (step === 0 && stopMove)
        console.log(`PtzPanel: stop move & zoom`)
      else
        console.log(`PtzPanel: zoom continuous, step=${step}, stopMove=${stopMove}`)

      let payload = { "zoomStep": step, "continuous": true }
      if (stopMove) payload['move'] = 0

      await this.runPtzContinuous(payload, force)
    },
    async runPtzContinuousStop() {
      await this.runZoomContinuous(0, true, true)
    },

    // gamepad related functions
    getFirstGamepad() {
      const gamepads = navigator.getGamepads()
      // console.log(gamepads)
      return gamepads && gamepads[0]
    },
    activateGamepad(gamepad) {
      console.log(`PtzPanel: activateGamepad ([${gamepad && gamepad.index}] ${gamepad && gamepad.id})`)
      if ((gamepad instanceof Gamepad) && (gamepad.index === 0)) {
        // only handle first gamepad
        this.gamepadConnected = true

        // map axes to XYZ
        // if (gamepad.id.includes('Vendor: 054c Product: 0061')) {
        //   // China Longcctv 3D Joystick Keyboard
        //   this.gamepadAxisMap = [0, 1, 2]
        // } else if (gamepad.id.includes('Vendor: 044f Product: b10a')) {
        //   // Thrustmaster T.16000M
        //   this.gamepadAxisMap = [0, 1, 2] // mapping is different on Win10 (0,1,5) and Ubuntu (0,1,2)
        // } else {
        //   this.gamepadAxisMap = [0, 1, 2]
        // }
        console.log(`PtzPanel: axes map = ${this.gamepadAxisMap}`)

        if (!this.gamepadUpdateInterval) {
          console.log('PtzPanel: update is started')
          this.gamepadUpdateInterval = setInterval(this.updateGamepadState, this.interval)
        }
      }
    },
    async deactivateGamepad(gamepad) {
      console.log(`PtzPanel: deactivateGamepad ([${gamepad && gamepad.index}] ${gamepad && gamepad.id})`)
      if ((gamepad instanceof Gamepad) && (gamepad.index === 0)) {
        // only handle first gamepad
        this.gamepadConnected = false

        console.log('PtzPanel: update is stopped')
        clearInterval(this.gamepadUpdateInterval)
        this.gamepadUpdateInterval = null

        while (this.stopCount++ < 3) {
          await this.runPtzContinuousStop()
        }
      }
    },
    handleGamepadConnected(e) {
      this.activateGamepad(e.gamepad)
    },
    handleGamepadDisconnected(e) {
      this.deactivateGamepad(e.gamepad)
    },
    handleVisibilityChange() {
      if (document.visibilityState === 'hidden') {
        console.log('PtzPanel: update is paused')
        this.deactivateGamepad(this.getFirstGamepad())
      } else {
        console.log('PtzPanel: update is resumed')
        this.activateGamepad(this.getFirstGamepad())
      }
    },
    enableGamepadControls() {
      console.log('PtzPanel: enableGamepadControls')
      this.activateGamepad(this.getFirstGamepad())

      window.addEventListener('gamepadconnected', this.handleGamepadConnected)
      window.addEventListener('gamepaddisconnected', this.handleGamepadDisconnected)
      document.addEventListener('visibilitychange', this.handleVisibilityChange)
    },
    disableGamepadControls() {
      console.log('PtzPanel: disableGamepadControls')
      document.removeEventListener('visibilitychange', this.handleVisibilityChange)
      window.removeEventListener('gamepaddisconnected', this.handleGamepadDisconnected)
      window.removeEventListener('gamepadconnected', this.handleGamepadConnected)

      this.deactivateGamepad(this.getFirstGamepad())
    },
    updateGamepadState() {
      if (document.visibilityState === 'visible') {
        const firstGamepad = this.getFirstGamepad()

        if (firstGamepad) {
          // console.log(`PtzPanel: updateGamepadState, axes-0=${firstGamepad.axes[0]}, axes-1=${firstGamepad.axes[1]}, axes-2=${firstGamepad.axes[2]}, axes-3=${firstGamepad.axes[3]}, axes-4=${firstGamepad.axes[4]}, axes-5=${firstGamepad.axes[5]}`)
          this.handleStick(
            firstGamepad.timestamp,
            firstGamepad.axes[this.gamepadAxisMap[0]],
            firstGamepad.axes[this.gamepadAxisMap[1]],
            firstGamepad.axes[this.gamepadAxisMap[2]])
        }
      }
    },
    handleStick(ts, x, y, z) {
      // console.log(`PtzPanel: handleStick, x=${x}, y=${y}, z=${z}`)
      x = (x !== undefined) ? Math.min(Math.max(Math.round(x * 10), -10), 10) : 0 // -1 ~ 1 -> -10, -9, ..., 9, 10
      y = (y !== undefined) ? Math.min(Math.max(Math.round(y * 10), -10), 10) : 0 // -1 ~ 1 -> -10, -9, ..., 9, 10
      z = (z !== undefined) ? Math.min(Math.max(Math.round(z *  7),  -7),  7) : 0 // -1 ~ 1 -> -7, -6, ..., 6, 7

      const tsNow = Date.now()
      let runStop = false

      if ((this.ptzRunningCount < this.ptzRunningMax) &&
          (((tsNow - this.lastPtzTs) >= 200) ||
          ((ts !== this.lastGamepadTs) && (x !== this.lastX) && (y !== this.lastY) && (z !== this.lastZ)))) {
        // console.log(`PtzPanel: now=${tsNow}, x=${x}, y=${y}, z=${z}`)
        if (x == 0 && y == 0 && z == 0) {
          // stop pt & z
          runStop = true
          if (this.stopCount < 3) {
            // console.log(`PtzPanel: run stop (${this.stopCount})`)
            this.runPtzContinuousStop()
          }
        } else if (z != 0) {
          // do zoom first
          x = y = 0
          this.runZoomContinuous(z, (this.lastX !== 0 || this.lastY !== 0))
        } else {
          // do pt
          let dir = 0
          const speed = Math.max(Math.abs(x), Math.abs(y))
          const angle = Math.atan2(y, x) // -pi ~ pi
          if (angle <= -Math.PI7_8 || angle >= Math.PI7_8) dir = 4 // left
          else if (angle < -Math.PI5_8) dir = 1                    // up-left
          else if (angle <= -Math.PI3_8) dir = 2                   // up
          else if (angle < -Math.PI1_8) dir = 3                    // up-right
          else if (angle <= Math.PI1_8) dir = 6                    // right
          else if (angle < Math.PI3_8) dir = 9                     // down-right
          else if (angle <= Math.PI5_8) dir = 8                    // down
          else if (angle < Math.PI7_8) dir = 7                     // down-left

          z = 0
          this.runMoveContinuous(dir, speed, (this.lastZ !== 0))
        }

        this.lastGamepadTs = ts
        this.lastX = x
        this.lastY = y
        this.lastZ = z
        this.lastPtzTs = tsNow
        this.stopCount = runStop ? Math.min(this.stopCount + 1, 3) : 0
      }
    }
  }
}
</script>

<style scoped>
.wrap-ptz-panel {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  background: #282942;
  padding: 12px;
  box-sizing: border-box;
  font-size: 24px;
  font-weight: 300;
  overflow: overlay;
}

.wrap-move-step {
  width: 22%;
  min-width: 150px;
  margin-right: 12px;
  background: #363d59;
  border-radius: 9px;
}

.wrap-set-step {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 8px;
  padding: 12px 10px 12px 16px;
  color: #ffffff;
  background: #363d59;
  border-radius: 0 0 9px 9px;
}

.set-step {
  display: flex;
  align-items: center;
}

.set-step > .step {
  user-select: none;
  min-width: 28px;
  text-align: center;
}

.minus-plus {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.minus-plus:not(.disabled):hover {
  background: #a5a5a580;
}

.minus-plus img {
  width: 20px;
  height: 20px;
}

.sliders {
  display: flex;
  flex-direction: column;
  width: 49%;
  min-width: 330px;
  flex: 1 1 49%;
  margin-right: 12px;
}

.slider {
  flex: 1;
  display: flex;
  align-items: center;
  background: #4A5C7866;
  border-radius: 9px;
  padding: 12px 20px;
  position: relative;
}

.slider:nth-child(2) {
  justify-content: space-around;
}

.slider:not(:last-child) {
  margin-bottom: 12px;
}

.slider:not(:nth-child(2))::before {
  content: '';
  position: absolute;
  top: 12px;
  left: 50%;
  width: 1px;
  height: calc(100% - 24px);
  background-color: #ffffff80;
}

.camera-param {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
}

.camera-param > .label {
  font-size: 20px;
  min-width: 80px;
  text-align: center;
}

.left {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: #FFFFFF;
}

.label {
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
}

.items {
  display: flex;
}

.item {
  width: 56px;
  height: 56px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.item:not(.disabled):hover {
  background: #ffffff1a;
}

.item img {
  width: 32px;
  height: 32px;
}

.ptz-active {
  filter: brightness(0) saturate(100%) invert(75%) sepia(51%) saturate(1825%) 
    hue-rotate(360deg) brightness(104%) contrast(102%);
}

.right {
  width: 50%;
  padding-left: 20px;
}

.speed-value {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
  color: #ffffff;
}

.disabled {
  opacity: 0.4;
  cursor: default;
}

.preset-list {
  width: 23%;
  min-width: 230px;
  padding-left: 12px;
  position: relative;
}

.preset-list::before {
  content: '';
  position: absolute;
  width: 1px;
  left: 0px;
  top: -12px;
  bottom: -12px;
  background: #4A5C78;
}

.preset-list .title {
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
  line-height: 40px;
}

.wrap-preset-items {
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
}

.preset-item {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background: #4A5C7866;
  border-radius: 9px;
  padding-left: 12px;
  padding-right: 6px;
  box-sizing: border-box;
}

.preset-item:not(:last-child) {
  margin-bottom: 8px;
}

.preset-item:hover, .active {
  background: #4A5C78;
}

.preset-item input {
  width: 100%;
  outline: none;
  border: none;
  background: transparent;
  color: #ffffff;
  font-size: 24px;
  line-height: 36px;
  font-weight: 300;
}

.preset-item input:focus {
  background: #282942;
}

.preset-item input:read-only:focus {
  background-color: #4A5C78;
}

.actions {
  display: flex;
  align-items: center;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  cursor: pointer;
}

.icon:hover {
  background: #ffffff33;
}

.icon img {
  width: 20px;
  height: 20px;
}

.save-txt {
  min-width: 40px;
  margin-left: 6px;
  font-size: 20px;
  font-weight: normal;
  color: #282942;
}

.save-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
  padding: 4px 10px;
  background: #FFD133;
  border-radius: 8px;
  cursor: pointer;
}

.save-btn img {
  width: 20px;
  height: 20px;
}

.switch-preset {
  display: none;
}

::-webkit-scrollbar {
  width: 5px;
  overflow: visible;
  height: 10px;
}
::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  margin:0px 0 5px 5px;
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background: #596486cc;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

@media screen and (max-width: 1800px) {
  .item {
    width: 49px;
    height: 49px;
  }

  .item img {
    width: 28px;
    height: 28px;
  }
}

@media screen and (max-width: 1600px) {
  .wrap-ptz-panel {
    font-size: 20px;
    line-height: 26px;
    padding: 8px;
  }

  .preset-list {
    padding-left: 16px;
  }

  .preset-list::before {
    left: 8px;
  }

  .preset-list .title {
    font-size: 20px;
  }
  
  .position {
    font-size: 18px;
    line-height: 24px;
  }

  .wrap-move-step {
    width: 220px;
    margin-right: 8px;
  }

  .sliders {
    flex: 1 1 49%;
    margin-right: 0;
  }

  .label {
    font-size: 18px;
    font-weight: 300;
    line-height: 36px;
  }

  .slider {
    padding: 8px 12px;
  }

  .slider:not(:nth-child(2))::before {
    left: 63%;
  }

  .left {
    width: 65%;
    padding-right: 8px;
  }

  .right {
    width: 35%;
  }

  .camera-param > .label {
    font-size: 18px;
  }

  .speed-value {
    font-size: 18px;
  }

  .item {
    width: 42px;
    height: 42px;
    border-radius: 6px;
  }

  .item img {
    width: 24px;
    height: 24px;
  }

  .item:not(:last-child) {
    margin-right: 0px;
  }

  .preset-item input {
    font-size: 18px;
  }

  .switch-preset {
    display: flex;
    align-items: center;
    position: absolute;
    left: -32px; 
    top: 8px;
    width: 32px;
    height: 32px;
    line-height: 32px;
    color: #ffffff;
    background: #66B3CC;
    border-radius: 5px 0 0 5px;
    padding-left: 4px;
    padding-right: 4px;
    box-sizing: border-box;
    cursor: pointer;
  }

  .switch-preset img {
    width: 24px;
    height: 24px;
    margin-right: 2px;
  }
}

@media screen and (max-width: 1280px) {
  .wrap-ptz-panel {
    font-size: 18px;
    line-height: 24px;
    justify-content: start;
  }
  
  .position {
    font-size: 16px;
    line-height: 22px;
  }
}
</style>