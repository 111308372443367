<template>
  <div ref="center" class="ptz-center-wrap" :style="center">
    <div class="top"></div>
    <div class="middle">
      <div class="left"></div>
      <div class="hold"></div>
      <div class="right"></div>
    </div>
    <div class="bottom"></div>
    <!-- <span :style="{color: '#f00'}">{{ JSON.stringify(parent) }}</span> -->
  </div>
</template>

<script>
// import {
//   mapState,
//   mapGetters
// } from 'vuex'

export default {
  name: 'Bullseye', // 靶心
  props: {
    parent: {
      require: true,
      type: Object,
      default() {
        return {}
      }
    },
    size: {
      type: Number,
      default: 70
    },
    // holdSize: {
    //   type: Number,
    //   default: 30
    // }
  },
  data() {
    return {}
  },
  computed: {
    // ...mapGetters('ptz', ['isSupportPtz']),
    center() {
      // console.log(`[center] parent:`, this.parent)
      return this.parent
    }
  },
  watch: {
    parent: {
      deep: true,
      handler() {
        this.locate()
      }
    }
  },
  methods: {
    locate() {
      this.$nextTick(() => {
        if (!this.parent) return

        let left = this.parent.left + this.parent.width / 2 - this.size / 2
        let top = this.parent.top + this.parent.height / 2 - this.size / 2
        this.$refs.center.style.left = `${left}px`
        this.$refs.center.style.top = `${top}px`
      })
    },
    onResize() {
      this.$emit('send')
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
    this.locate()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style lang="scss" scoped>
$PtzCenterSize: px2rem(70);
$PtzCenterHoldSize: px2rem(30);
$PtzCenterLineW: px2rem(3);
$PtzCenterLineLen: px2rem(calc((70 - 30) / 2));
// $PtzCenterLineColor: #FF6100; // Windows App default color
$PtzCenterLineColor: #00f;
// $PtzCenterLineColor: $color_DB2B39;
.ptz-center-wrap {
  position: fixed;
  // left: 0;
  // top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: $PtzCenterSize;
  height: $PtzCenterSize;
  // background-color: #00000033;
  .top,
  .bottom {
    width: $PtzCenterLineW;
    height: $PtzCenterLineLen;
    background-color: $PtzCenterLineColor;
  }

  .left,
  .right {
    width: $PtzCenterLineLen;
    height: $PtzCenterLineW;
    background-color: $PtzCenterLineColor;
  }
  .middle {
    display: flex;
    align-items: center;
    .hold {
      width: $PtzCenterHoldSize;
      height: $PtzCenterHoldSize;
      // background-color: #ff0;
    }
  }

  .bottom {
    width: $PtzCenterLineW;
    height: $PtzCenterLineLen;
    background-color: $PtzCenterLineColor;
  }
}
</style>
